import { ApiGet } from "../../helper/API/ApiData";
import { IS_LOADING, DASHBOARD_DATA_LOADING, DASHBOARD_DATA, DASHBOARD_DATA_ERROR } from "../type";

export const DashboardAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DASHBOARD_DATA_LOADING,
            payload: true
        })

        await ApiGet('dashboard/dashboard')
            .then((res) => {
                dispatch({
                    type: DASHBOARD_DATA,
                    payload: res
                })
            })

        dispatch({
            type: DASHBOARD_DATA_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: DASHBOARD_DATA_ERROR,
            payload: err
        })

        dispatch({
            type: DASHBOARD_DATA_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}