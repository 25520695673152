import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import ErrorImg from '../../assets/img/error.png'
import AuthStorage from '../../helper/AuthStorage'
const Error = () => {

    const navigate = useNavigate()

    const redirect = () => {
        const getToken = AuthStorage.getToken()
        if (getToken) {
            navigate('/dashboard')
        }
        else {
            navigate('/')
        }
    }

    return (
        <div className='error-bg'>
            <div className='error-main' style={{}}>
                <img src={ErrorImg} className="error-img" />
                <h5 className='error-title'>Error 404</h5>
                <h6 className='error-text'>The Page you are looking for does not exist.</h6>
                <Button className="save-btn" onClick={redirect}>Go to Home</Button>
            </div>
        </div>
    )
}

export default Error