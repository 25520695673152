import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getBloggerById } from '../../redux/Action/BloggerAction'
import { BLOGGER_GET_BY_ID } from '../../redux/type'
import Loading from '../../layout/Loading'

const ViewBlogger = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()
    const [bloggerAccount, setBloggerAccount] = useState()
    const gateByIdBloggerAccount = useSelector(state => state.bloggeraccount.getbyidblogger)
    const loading = useSelector(state => state.bloggeraccount.loading)

    useEffect(() => {
        if (id) {
            dispatch(getBloggerById(id))
        }
    }, [id])

    useEffect(() => {
        return () => {
            dispatch({
                type: BLOGGER_GET_BY_ID,
                payload: ''
            })
        }
    }, [])


    useEffect(() => {
        if (gateByIdBloggerAccount) {
            setBloggerAccount(Object.keys(gateByIdBloggerAccount).filter(ele => !['_id', '__v', 'isDeleted', 'application'].includes(ele)).map(key => {
                if (['createdAt', 'updatedAt'].includes(key)) {
                    return ({ key: key, value: moment(gateByIdBloggerAccount[key]).format('DD.MM.YYYY') })
                }
                if (gateByIdBloggerAccount[key] && typeof gateByIdBloggerAccount[key] === "object") {
                    return Object.keys(gateByIdBloggerAccount[key]).filter(ele => !['_id', '__v', 'isDeleted'].includes(ele)).map(key_ele => {
                        return ({ key: `${key}`, value: gateByIdBloggerAccount[key][key_ele] })
                    })

                } else {
                    return ({ key: key, value: gateByIdBloggerAccount[key] })
                }
            }).flat(1))
        }
    }, [gateByIdBloggerAccount])


    return (
        <>
            {loading ? <Loading /> : (
                <div className='android-main'>
                    <div className='android-main-card p-0'>
                        <div className='card__header'>
                            <div style={{ display: 'flex', gap: '20px', alignItems: 'center', flexWrap: 'wrap' }}>
                                {/* <img src={gateByIdBloggerAccount?.logo} alt="" width="30px" /> */}
                                <h1 className='title'>
                                    {gateByIdBloggerAccount?.consoleName}
                                </h1>
                            </div>
                            <div className='header_buttons'>
                                <Button className='header_cancel_button' onClick={() => navigate(`/edit-blogger/${id}`)}>Edit</Button>
                                <Button className='header_cancel_button' onClick={() => navigate('/blogger-account')}>Back</Button>
                            </div>
                        </div>
                        <table className='view_table'>
                            <tbody style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {
                                    bloggerAccount && bloggerAccount.map((item) => {
                                        return (
                                            <tr style={{ width: '50%', display: 'flex', justifyContent: 'space-around' }}>
                                                <td>{item.key}</td>
                                                <td>
                                                    {item.value}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr style={{ width: '50%', display: 'flex', justifyContent: 'space-around' }}>
                                    <td>Apps</td>
                                    <td>
                                        {gateByIdBloggerAccount && gateByIdBloggerAccount.application && gateByIdBloggerAccount.application.map(app => (
                                            <a style={{ marginRight: '10px' }} href={app.appLink} target="_blank">
                                                <img className='app_logo' src={app.logo} alt='logo' />
                                            </a>
                                        ))}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    )
}

export default ViewBlogger