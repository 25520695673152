import React, { useEffect, useState, useMemo } from 'react'
import debounce from 'lodash.debounce';
import { Button, Form } from 'react-bootstrap';
import Table from '../../components/table/Table';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { DeleteMultipleDeveloperAction, DeveloperAction, getDeveloperById } from '../../redux/Action/DeveloperAction';
import { DELETE_MULTIPLE_DEVELOPER } from '../../redux/type';
import { toast } from 'react-toastify';
import { AccountStatus } from '../../helper/Constant';
import Loading from '../../layout/Loading';
import DeleteLogo from './../../assets/img/Delete.png'
import DeleteModal from '../../components/modals/DeleteModal';

const DeveloperAccount = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const urlParamStatus = searchParams.get('status')?.toUpperCase()

    const [allDeveloperAccount, setAllDeveloperAccount] = useState([])
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 50,
    })
    const [totalCount, setTotalCount] = useState(0)
    const [filterStatus, setFilterStatus] = useState(urlParamStatus ? urlParamStatus : '')
    const [search, setSearch] = useState('')
    const [selectedRows, setSelectedRows] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)

    const developerAccount = useSelector(state => state.developeraccount.app)
    const deleteMultiple = useSelector(state => state.developeraccount.deletemultiacc)
    const loading = useSelector(state => state.developeraccount.loading)

    useEffect(() => {
        dispatch(DeveloperAction(pagination, search, filterStatus))
    }, [search, pagination, filterStatus, urlParamStatus])

    useEffect(() => {
        if (developerAccount) {
            setAllDeveloperAccount(developerAccount.data)
            setTotalCount(developerAccount.count)
        }
    }, [developerAccount])

    useEffect(() => {
        if (deleteMultiple && deleteMultiple.status === 200) {
            dispatch({
                type: DELETE_MULTIPLE_DEVELOPER,
                payload: ""
            })
            toast.success(deleteMultiple.message)
            setSelectedRows([])
            dispatch(DeveloperAction(pagination, search, filterStatus))
        }
    }, [deleteMultiple])


    const columns = [
        {
            id: 'application',
            numeric: false,
            disablePadding: true,
            label: 'Apps',
            selector: 'application'
        },
        {
            id: 'consoleName',
            numeric: false,
            disablePadding: true,
            label: 'Console name',
            selector: 'consoleName'
        },
        {
            id: 'subDevice',
            numeric: false,
            disablePadding: true,
            label: 'Sub Device (Access)',
            selector: 'subDevice'
        },
        // {
        //     id: 'email',
        //     numeric: true,
        //     disablePadding: false,
        //     label: 'Email id',
        //     selector: 'email'
        // },
        // {
        //     id: 'mobile',
        //     numeric: true,
        //     disablePadding: false,
        //     label: 'Mobile no',
        //     selector: 'mobile'
        // },
        // {
        //     id: 'device',
        //     numeric: true,
        //     disablePadding: false,
        //     label: 'Device',
        //     selector: 'device'
        // },
        // {
        //     id: 'bloggerAccountMap',
        //     numeric: true,
        //     disablePadding: false,
        //     label: 'Blogger account map',
        //     selector: 'bloggerAccountMap'
        // },
        {
            id: 'status',
            numeric: true,
            disablePadding: false,
            label: 'Status',
            selector: 'status'
        },
        {
            id: 'action',
            numeric: true,
            disablePadding: false,
            label: 'Action',
            selector: 'action',
            action_type: ['VIEW', 'EDIT'],
        },
    ];

    const getAction = (obj) => {
        if (obj.type === "VIEW") {
            navigate(`/view-developer/${obj.id}`)
            dispatch(getDeveloperById(obj.id))
        }
        if (obj.type === "EDIT") {
            navigate(`/edit-developer/${obj.id}`)
        }
    }

    const handleFilterStatus = (e) => {
        setPagination({ ...pagination, page: 0 })
        setFilterStatus(e.target.value)
    }

    const handleChange = useMemo(
        () =>
            debounce((e) => setSearch(e.target.value), 1000)
        , [search]);

    useEffect(() => {
        return () => handleChange.cancel();
    }, []);

    const handleMultipleDelete = () => {
        setDeleteModal(true)
    }

    const onConfirmDelete = () => {
        dispatch(DeleteMultipleDeveloperAction(selectedRows))
        setDeleteModal(false)
    }

    return (
        <>

            <div className='android-main'>
                {/* <div className='text-end mb-3'>
                </div> */}
                <div className='android-main-card p-0'>
                    <div className='card__header'>
                        <h1 className='title'>Developer Account</h1>
                        <div className='card_filter'>
                            {selectedRows.length > 0 && (
                                <div >
                                    <img className='multi_delete_icon' onClick={handleMultipleDelete} src={DeleteLogo} alt='' />
                                </div>
                            )}
                            <div>
                                <Form.Select className='form-input' name='status' value={filterStatus} onChange={handleFilterStatus} aria-label="Default select example">
                                    <option value=''>All</option>
                                    <option value={AccountStatus.Active}>{AccountStatus.Active}</option>
                                    <option value={AccountStatus.Suspended}>{AccountStatus.Suspended}</option>
                                </Form.Select>
                            </div>
                            <div className='card_filter_search'>
                                <input type='search' placeholder=' Search...' defaultValue={search} onChange={handleChange}></input>
                            </div>
                            <Button className='header_button' onClick={() => navigate('/add-developer')}>Add Developer</Button>
                        </div>
                    </div>
                    {(loading && !allDeveloperAccount.length) ? <Loading /> : (
                        <Table
                            headCells={columns}
                            rows={allDeveloperAccount}
                            getAction={getAction}
                            pagination={pagination}
                            setPagination={setPagination}
                            count={totalCount}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                        />
                    )}
                </div>
            </div>
            {deleteModal && <DeleteModal show={deleteModal} onHide={() => setDeleteModal(false)} onConfirmDelete={onConfirmDelete} />}
        </>
    )
}

export default DeveloperAccount