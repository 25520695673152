export const IS_LOADING = "IS_LOADING"

export const IS_LOGIN_LOADING = "IS_LOGIN_LOADING"
export const LOGIN = "LOGIN"
export const IS_LOGIN_ERROR = "IS_LOGIN_ERROR"

export const IS_REGISTER_LOADING = "IS_REGISTER_LOADING"
export const REGISTER = "REGISTER"
export const IS_REGISTER_ERROR = "IS_REGISTER_ERROR"

export const DELETE_USER_LOADING = "DELETE_USER_LOADING"
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_ERROR = "DELETE_USER_ERROR"

export const GET_USERS_LOADING = "GET_USERS_LOADING"
export const GET_USERS = "GET_USERS"
export const GET_USERS_ERROR = "GET_USERS_ERROR"

export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR"

{/* <======================= Dashboard =======================> */ }

export const DASHBOARD_DATA_LOADING = "DASHBOARD_DATA_LOADING"
export const DASHBOARD_DATA = "DASHBOARD_DATA"
export const DASHBOARD_DATA_ERROR = "DASHBOARD_DATA_ERROR"

{/* <======================= All Apps =======================> */ }

export const ALL_APPS_LOADING = "ALL_APPS_LOADING"
export const ALL_APPS = "ALL_APPS"
export const ALL_APPS_ERROR = "ALL_APPS_ERROR"

export const DELETE_MULTIPLE_APPS_LOADING = "DELETE_MULTIPLE_APPS_LOADING"
export const DELETE_MULTIPLE_APPS = "DELETE_MULTIPLE_APPS"
export const DELETE_MULTIPLE_APPS_ERROR = "DELETE_MULTIPLE_APPS_ERROR"

{/* <======================= Apps With Name And Id =======================> */ }

export const ALL_APPS_NAME_ID_LOADING = "ALL_APPS_NAME_ID_LOADING"
export const ALL_APPS_NAME_ID = "ALL_APPS_NAME_ID"
export const ALL_APPS_NAME_ID_ERROR = "ALL_APPS_NAME_ID_ERROR"

{/* <======================= Android =======================> */ }

export const ANDROID_APP_LOADING = "ANDROID_APP_LOADING"
export const ANDROID_APP = "ANDROID_APP"
export const ANDROID_APP_ERROR = "ANDROID_APP_ERROR"

export const ADD_ANDROID_APP_LOADING = "ADD_ANDROID_APP_LOADING"
export const ADD_ANDROID_APP = "ADD_ANDROID_APP"
export const ADD_ANDROID_APP_ERROR = "ADD_ANDROID_APP_ERROR"

export const DELETE_ANDROID_APP_LOADING = "DELETE_ANDROID_APP_LOADING"
export const DELETE_ANDROID_APP = "DELETE_ANDROID_APP"
export const DELETE_ANDROID_APP_ERROR = "DELETE_ANDROID_APP_ERROR"

export const DELETE_MULTIPLE_ANDROID_APP_LOADING = "DELETE_MULTIPLE_ANDROID_APP_LOADING"
export const DELETE_MULTIPLE_ANDROID_APP = "DELETE_MULTIPLE_ANDROID_APP"
export const DELETE_MULTIPLE_ANDROID_APP_ERROR = "DELETE_MULTIPLE_ANDROID_APP_ERROR"

export const EDIT_ANDROID_APP_LOADING = "EDIT_ANDROID_APP_LOADING"
export const EDIT_ANDROID_APP = "EDIT_ANDROID_APP"
export const EDIT_ANDROID_APP_ERROR = "EDIT_ANDROID_APP_ERROR"

export const GET_BY_ID_ANDROID_APP_LOADING = "GET_BY_ID_ANDROID_APP_LOADING"
export const GET_BY_ID_ANDROID_APP = "GET_BY_ID_ANDROID_APP"
export const GET_BY_ID_ANDROID_APP_ERROR = "GET_BY_ID_ANDROID_APP_ERROR"

// export const ANDROID_UPDATE_FAVORITE_LOADING = "ANDROID_UPDATE_FAVORITE_LOADING"
// export const ANDROID_UPDATE_FAVORITE = "ANDROID_UPDATE_FAVORITE"
// export const ANDROID_UPDATE_FAVORITE_ERROR = "ANDROID_UPDATE_FAVORITE_ERROR"

{/* <======================= IOS =======================> */ }

export const IOS_LOADING = "IOS_LOADING"
export const IOS_APP = "IOs_APP"
export const IOS_ERROR = "IOS_ERROR"

export const IOS_ADD_LOADING = "IOS_ADD_LOADING"
export const IOS_ADD_APP = "IOS_ADD_APP"
export const IOS_ADD_ERROR = "IOS_ADD_ERROR"

export const IOS_DELETE_LOADING = "IOS_DELETE_LOADING"
export const IOS_DELETE_APP = "IOS_DELETE_APP"
export const IOS_DELETE_ERROR = "IOS_DELETE_ERROR"

export const DELETE_MULTIPLE_IOS_APP_LOADING = "DELETE_MULTIPLE_IOS_APP_LOADING"
export const DELETE_MULTIPLE_IOS_APP = "DELETE_MULTIPLE_IOS_APP"
export const DELETE_MULTIPLE_IOS_APP_ERROR = "DELETE_MULTIPLE_IOS_APP_ERROR"

export const IOS_EDIT_LOADING = "IOS_EDIT_LOADING"
export const IOS_EDIT_APP = "IOS_EDIT_APP"
export const IOS_EDIT_ERROR = "IOS_EDIT_ERROR"

export const IOS_GET_BY_ID_LOADING = "IOS_GET_BY_ID_LOADING"
export const IOS_GET_BY_ID_APP = "IOS_GET_BY_ID_APP"
export const IOS_GET_BY_ID_ERROR = "IOS_GET_BY_ID_ERROR"

{/* <======================= DEVELOPER Account =======================> */ }

export const DEVELOPER_LOADING = "DEVELOPER_LOADING"
export const DEVELOPER_APP = "DEVELOPER_APP"
export const DEVELOPER_ERROR = "DEVELOPER_ERROR"

export const ADD_DEVELOPER_LOADING = "ADD_DEVELOPER_LOADING"
export const ADD_DEVELOPER_APP = "ADD_DEVELOPER_APP"
export const ADD_DEVELOPER_ERROR = "ADD_DEVELOPER_ERROR"

export const DELETE_DEVELOPER_LOADING = "DELETE_DEVELOPER_LOADING"
export const DELETE_DEVELOPER = "DELETE_DEVELOPER"
export const DELETE_DEVELOPER_ERROR = "DELETE_DEVELOPER_ERROR"

export const DELETE_MULTIPLE_DEVELOPER_LOADING = "DELETE_MULTIPLE_DEVELOPER_LOADING"
export const DELETE_MULTIPLE_DEVELOPER = "DELETE_MULTIPLE_DEVELOPER"
export const DELETE_MULTIPLE_DEVELOPER_ERROR = "DELETE_MULTIPLE_DEVELOPER_ERROR"

export const EDIT_DEVELOPER_LOADING = "EDIT_DEVELOPER_LOADING"
export const EDIT_DEVELOPER = "EDIT_DEVELOPER"
export const EDIT_DEVELOPER_ERROR = "EDIT_DEVELOPER_ERROR"

export const DEVELOPER_GET_BY_ID_LOADING = "DEVELOPER_GET_BY_ID_LOADING"
export const DEVELOPER_GET_BY_ID = "DEVELOPER_GET_BY_ID"
export const DEVELOPER_GET_BY_ID_ERROR = "DEVELOPER_GET_BY_ID_ERROR"

{/* <======================= BLOGGER Account =======================> */ }

export const BLOGGER_LOADING = "BLOGGER_LOADING"
export const BLOGGER_ACCOUNT = "BLOGGER_APP"
export const BLOGGER_ERROR = "BLOGGER_ERROR"

export const ADD_BLOGGER_LOADING = "ADD_BLOGGER_LOADING"
export const ADD_BLOGGER_ACCOUNT = "ADD_BLOGGER_ACCOUNT"
export const ADD_BLOGGER_ERROR = "ADD_BLOGGER_ERROR"

export const DELETE_BLOGGER_LOADING = "DELETE_BLOGGER_LOADING"
export const DELETE_BLOGGER = "DELETE_BLOGGER"
export const DELETE_BLOGGER_ERROR = "DELETE_BLOGGER_ERROR"

export const DELETE_MULTIPLE_BLOGGER_LOADING = "DELETE_MULTIPLE_BLOGGER_LOADING"
export const DELETE_MULTIPLE_BLOGGER = "DELETE_MULTIPLE_BLOGGER"
export const DELETE_MULTIPLE_BLOGGER_ERROR = "DELETE_MULTIPLE_BLOGGER_ERROR"

export const EDIT_BLOGGER_LOADING = "EDIT_BLOGGER_LOADING"
export const EDIT_BLOGGER = "EDIT_BLOGGER"
export const EDIT_BLOGGER_ERROR = "EDIT_BLOGGER_ERROR"

export const BLOGGER_GET_BY_ID_LOADING = "BLOGGER_GET_BY_ID_LOADING"
export const BLOGGER_GET_BY_ID = "BLOGGER_GET_BY_ID"
export const BLOGGER_GET_BY_ID_ERROR = "BLOGGER_GET_BY_ID_ERROR"

{/* <======================= Other =======================> */ }

export const OTHER_APP_LOADING = "OTHER_APP_LOADING"
export const OTHER_APP = "OTHER_APP"
export const OTHER_APP_ERROR = "OTHER_APP_ERROR"

export const ADD_OTHER_APP_LOADING = "ADD_OTHER_APP_LOADING"
export const ADD_OTHER_APP = "ADD_OTHER_APP"
export const ADD_OTHER_APP_ERROR = "ADD_OTHER_APP_ERROR"

export const DELETE_OTHER_APP_LOADING = "DELETE_OTHER_APP_LOADING"
export const DELETE_OTHER_APP = "DELETE_OTHER_APP"
export const DELETE_OTHER_APP_ERROR = "DELETE_OTHER_APP_ERROR"

export const DELETE_MULTIPLE_OTHER_APPS_LOADING = "DELETE_MULTIPLE_OTHER_APPS_LOADING"
export const DELETE_MULTIPLE_OTHER_APPS = "DELETE_MULTIPLE_OTHER_APPS"
export const DELETE_MULTIPLE_OTHER_APPS_ERROR = "DELETE_MULTIPLE_OTHER_APPS_ERROR"

export const EDIT_OTHER_APP_LOADING = "EDIT_OTHER_APP_LOADING"
export const EDIT_OTHER_APP = "EDIT_OTHER_APP"
export const EDIT_OTHER_APP_ERROR = "EDIT_OTHER_APP_ERROR"

export const GET_BY_ID_OTHER_APP_LOADING = "GET_BY_ID_OTHER_APP_LOADING"
export const GET_BY_ID_OTHER_APP = "GET_BY_ID_OTHER_APP"
export const GET_BY_ID_OTHER_APP_ERROR = "GET_BY_ID_OTHER_APP_ERROR"

{/* <======================= Activity Log =======================> */ }

export const ACTIVITY_LOG_LOADING = "ACTIVITY_LOG_LOADING"
export const ACTIVITY_LOG = "ACTIVITY_LOG"
export const ACTIVITY_LOG_ERROR = "ACTIVITY_LOG_ERROR"

export const NOTIFICATION_ACTIVITY_LOG_LOADING = "NOTIFICATION_ACTIVITY_LOG_LOADING"
export const NOTIFICATION_ACTIVITY_LOG = "NOTIFICATION_ACTIVITY_LOG"
export const NOTIFICATION_ACTIVITY_LOG_ERROR = "NOTIFICATION_ACTIVITY_LOG_ERROR"

export const ACTIVITY_UPDATE_LOADING = "ACTIVITY_UPDATE_LOADING"
export const ACTIVITY_UPDATE = "ACTIVITY_UPDATE"
export const ACTIVITY_UPDATE_ERROR = "ACTIVITY_UPDATE_ERROR"

export const DELETE_MULTIPLE_ACTIVITIES_LOADING = "DELETE_MULTIPLE_ACTIVITIES_LOADING"
export const DELETE_MULTIPLE_ACTIVITIES = "DELETE_MULTIPLE_ACTIVITIES"
export const DELETE_MULTIPLE_ACTIVITIES_ERROR = "DELETE_MULTIPLE_ACTIVITIES_ERROR"