import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { AppType } from "../../helper/Constant"
import { ADD_ANDROID_APP, ADD_ANDROID_APP_ERROR, ADD_ANDROID_APP_LOADING, ANDROID_APP, ANDROID_APP_ERROR, ANDROID_APP_LOADING, DELETE_ANDROID_APP, DELETE_ANDROID_APP_ERROR, DELETE_ANDROID_APP_LOADING, DELETE_MULTIPLE_ANDROID_APP, DELETE_MULTIPLE_ANDROID_APP_ERROR, DELETE_MULTIPLE_ANDROID_APP_LOADING, EDIT_ANDROID_APP, EDIT_ANDROID_APP_ERROR, EDIT_ANDROID_APP_LOADING, GET_BY_ID_ANDROID_APP, GET_BY_ID_ANDROID_APP_ERROR, GET_BY_ID_ANDROID_APP_LOADING, IS_LOADING } from "../type"

export const AndroidAppAction = (pagination, search, filter, isFavorite) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ANDROID_APP_LOADING,
            payload: true
        })


        await ApiGet(`application/get_apps_by_status_OR_type?appType=${AppType.Android}&page=${pagination.page}&limit=${pagination.limit}&search=${search}&filter=${filter}&favorite=${isFavorite}`)
            .then((res) => {
                dispatch({
                    type: ANDROID_APP,
                    payload: res
                })
            })

        dispatch({
            type: ANDROID_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: ANDROID_APP_ERROR,
            payload: err
        })

        dispatch({
            type: ANDROID_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const AddAndroidAppAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_ANDROID_APP_LOADING,
            payload: true
        })


        await ApiPost('application', body)
            .then((res) => {
                dispatch({
                    type: ADD_ANDROID_APP,
                    payload: res
                })
            })

        dispatch({
            type: ADD_ANDROID_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: ADD_ANDROID_APP_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_ANDROID_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const DeleteAndroidAppAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_ANDROID_APP_LOADING,
            payload: true
        })


        await ApiDelete(`application/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_ANDROID_APP,
                    payload: res
                })
            })

        dispatch({
            type: DELETE_ANDROID_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: DELETE_ANDROID_APP_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_ANDROID_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const EditAndroidAppAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_ANDROID_APP_LOADING,
            payload: true
        })


        await ApiPut('application', body)
            .then((res) => {
                dispatch({
                    type: EDIT_ANDROID_APP,
                    payload: res
                })
            })

        dispatch({
            type: EDIT_ANDROID_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: EDIT_ANDROID_APP_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_ANDROID_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getByIdAndroidAppAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_BY_ID_ANDROID_APP_LOADING,
            payload: true
        })

        await ApiGet(`application/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_BY_ID_ANDROID_APP,
                    payload: res
                })
            })

        dispatch({
            type: GET_BY_ID_ANDROID_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: GET_BY_ID_ANDROID_APP_ERROR,
            payload: err
        })

        dispatch({
            type: GET_BY_ID_ANDROID_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const DeleteMultipleAndroidAppAction = (ids) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_MULTIPLE_ANDROID_APP_LOADING,
            payload: true
        })


        await ApiPost('application/delete_multiple', { ids })
            .then((res) => {
                dispatch({
                    type: DELETE_MULTIPLE_ANDROID_APP,
                    payload: res
                })
            })

        dispatch({
            type: DELETE_MULTIPLE_ANDROID_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: DELETE_MULTIPLE_ANDROID_APP_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_MULTIPLE_ANDROID_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

// export const EditAndroidFavoriteAction = (id) => async (dispatch) => {
//     try {
//         // dispatch({
//         //     type: IS_LOADING,
//         //     payload: true
//         // })

//         dispatch({
//             type: ANDROID_UPDATE_FAVORITE_LOADING,
//             payload: true
//         })


//         await ApiPut(`application/update_favorite/${id}`)
//             .then((res) => {
//                 dispatch({
//                     type: ANDROID_UPDATE_FAVORITE,
//                     payload: res
//                 })
//             })

//         dispatch({
//             type: ANDROID_UPDATE_FAVORITE_LOADING,
//             payload: false
//         })

//         dispatch({
//             type: IS_LOADING,
//             payload: false
//         })

//     } catch (err) {
//         dispatch({
//             type: ANDROID_UPDATE_FAVORITE_ERROR,
//             payload: err
//         })

//         dispatch({
//             type: ANDROID_UPDATE_FAVORITE_LOADING,
//             payload: false
//         })

//         // dispatch({
//         //     type: IS_LOADING,
//         //     payload: false
//         // })
//     }
// }