import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { AppType } from "../../helper/Constant"
import { ADD_OTHER_APP, ADD_OTHER_APP_ERROR, ADD_OTHER_APP_LOADING, OTHER_APP, OTHER_APP_ERROR, OTHER_APP_LOADING, DELETE_OTHER_APP, DELETE_OTHER_APP_ERROR, DELETE_OTHER_APP_LOADING, EDIT_OTHER_APP, EDIT_OTHER_APP_ERROR, EDIT_OTHER_APP_LOADING, GET_BY_ID_OTHER_APP, GET_BY_ID_OTHER_APP_ERROR, GET_BY_ID_OTHER_APP_LOADING, IS_LOADING, DELETE_MULTIPLE_OTHER_APPS_LOADING, DELETE_MULTIPLE_OTHER_APPS_ERROR, DELETE_MULTIPLE_OTHER_APPS } from "../type"

export const OtherAppAction = (pagination, search, filter) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: OTHER_APP_LOADING,
            payload: true
        })


        await ApiGet(`other?page=${pagination.page}&limit=${pagination.limit}&search=${search}&filter=${filter}`)
            .then((res) => {
                dispatch({
                    type: OTHER_APP,
                    payload: res
                })
            })

        dispatch({
            type: OTHER_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: OTHER_APP_ERROR,
            payload: err
        })

        dispatch({
            type: OTHER_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const AddOtherApp = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_OTHER_APP_LOADING,
            payload: true
        })


        await ApiPost('other', body)
            .then((res) => {
                dispatch({
                    type: ADD_OTHER_APP,
                    payload: res
                })
            })

        dispatch({
            type: ADD_OTHER_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: ADD_OTHER_APP_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_OTHER_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const DeleteOtherApp = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_OTHER_APP_LOADING,
            payload: true
        })


        await ApiDelete(`other/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_OTHER_APP,
                    payload: res
                })
            })

        dispatch({
            type: DELETE_OTHER_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: DELETE_OTHER_APP_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_OTHER_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const EditOtherApp = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_OTHER_APP_LOADING,
            payload: true
        })


        await ApiPut('other', body)
            .then((res) => {
                dispatch({
                    type: EDIT_OTHER_APP,
                    payload: res
                })
            })

        dispatch({
            type: EDIT_OTHER_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: EDIT_OTHER_APP_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_OTHER_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getByIdOtherApp = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_BY_ID_OTHER_APP_LOADING,
            payload: true
        })

        await ApiGet(`other/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_BY_ID_OTHER_APP,
                    payload: res
                })
            })

        dispatch({
            type: GET_BY_ID_OTHER_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: GET_BY_ID_OTHER_APP_ERROR,
            payload: err
        })

        dispatch({
            type: GET_BY_ID_OTHER_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const DeleteMultipleOtherApp = (ids) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_MULTIPLE_OTHER_APPS_LOADING,
            payload: true
        })


        await ApiPost('other/delete_multiple', { ids })
            .then((res) => {
                dispatch({
                    type: DELETE_MULTIPLE_OTHER_APPS,
                    payload: res
                })
            })

        dispatch({
            type: DELETE_MULTIPLE_OTHER_APPS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: DELETE_MULTIPLE_OTHER_APPS_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_MULTIPLE_OTHER_APPS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}