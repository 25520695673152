import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import { AppStatus } from "../../helper/Constant";
import { IS_LOADING, ALL_APPS, ALL_APPS_ERROR, ALL_APPS_LOADING, ALL_APPS_NAME_ID, ALL_APPS_NAME_ID_ERROR, ALL_APPS_NAME_ID_LOADING, DELETE_MULTIPLE_APPS_LOADING, DELETE_MULTIPLE_APPS_ERROR, DELETE_MULTIPLE_APPS } from "../type";

export const AllAppsAction = (pagination, search, filter) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ALL_APPS_LOADING,
            payload: true
        })

        await ApiGet(`application/get_all_apps?page=${pagination.page}&limit=${pagination.limit}&search=${search}&filter=${filter}`)
            .then((res) => {
                dispatch({
                    type: ALL_APPS,
                    payload: res
                })
            })

        dispatch({
            type: ALL_APPS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: ALL_APPS_ERROR,
            payload: err
        })

        dispatch({
            type: ALL_APPS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const AllAppsNameIdAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ALL_APPS_NAME_ID_LOADING,
            payload: true
        })

        await ApiGet("application/get_apps_name_and_id")
            .then((res) => {
                dispatch({
                    type: ALL_APPS_NAME_ID,
                    payload: res
                })
            })

        dispatch({
            type: ALL_APPS_NAME_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: ALL_APPS_NAME_ID_ERROR,
            payload: err
        })

        dispatch({
            type: ALL_APPS_NAME_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const DeleteMultipleAppsAction = (ids) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_MULTIPLE_APPS_LOADING,
            payload: true
        })


        await ApiPost('application/delete_multiple', { ids })
            .then((res) => {
                dispatch({
                    type: DELETE_MULTIPLE_APPS,
                    payload: res
                })
            })

        dispatch({
            type: DELETE_MULTIPLE_APPS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: DELETE_MULTIPLE_APPS_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_MULTIPLE_APPS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}