import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { AppType } from "../../helper/Constant"
import { DELETE_MULTIPLE_IOS_APP, DELETE_MULTIPLE_IOS_APP_ERROR, DELETE_MULTIPLE_IOS_APP_LOADING, IOS_ADD_APP, IOS_ADD_ERROR, IOS_ADD_LOADING, IOS_APP, IOS_DELETE_APP, IOS_DELETE_ERROR, IOS_DELETE_LOADING, IOS_EDIT_APP, IOS_EDIT_ERROR, IOS_EDIT_LOADING, IOS_ERROR, IOS_GET_BY_ID_APP, IOS_GET_BY_ID_ERROR, IOS_GET_BY_ID_LOADING, IOS_LOADING, IS_LOADING } from "../type"

export const IOSAction = (pagination, search, filter, isFavorite) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: IOS_LOADING,
            payload: true
        })


        await ApiGet(`application/get_apps_by_status_OR_type?appType=${AppType.Ios}&page=${pagination.page}&limit=${pagination.limit}&search=${search}&filter=${filter}&favorite=${isFavorite}`)
            .then((res) => {
                dispatch({
                    type: IOS_APP,
                    payload: res
                })
            })

        dispatch({
            type: IOS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: IOS_ERROR,
            payload: err
        })

        dispatch({
            type: IOS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const AddIOSApp = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: IOS_ADD_LOADING,
            payload: true
        })


        await ApiPost('application', body)
            .then((res) => {
                dispatch({
                    type: IOS_ADD_APP,
                    payload: res
                })
            })

        dispatch({
            type: IOS_ADD_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: IOS_ADD_ERROR,
            payload: err
        })

        dispatch({
            type: IOS_ADD_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const DeleteIOSApp = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: IOS_DELETE_LOADING,
            payload: true
        })


        await ApiDelete(`application/${id}`)
            .then((res) => {
                dispatch({
                    type: IOS_DELETE_APP,
                    payload: res
                })
                // dispatch({
                //     type: IOS_APP,
                //     payload: res.data.data
                // })
            })

        dispatch({
            type: IOS_DELETE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: IOS_DELETE_ERROR,
            payload: err
        })

        dispatch({
            type: IOS_DELETE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const EditIOSApp = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: IOS_EDIT_LOADING,
            payload: true
        })


        await ApiPut("application", body)
            .then((res) => {
                dispatch({
                    type: IOS_EDIT_APP,
                    payload: res
                })
            })

        dispatch({
            type: IOS_EDIT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: IOS_EDIT_ERROR,
            payload: err
        })

        dispatch({
            type: IOS_EDIT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const gateByIdIOSApp = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: IOS_GET_BY_ID_LOADING,
            payload: true
        })


        await ApiGet(`application/${id}`)
            .then((res) => {
                dispatch({
                    type: IOS_GET_BY_ID_APP,
                    payload: res
                })
            })

        dispatch({
            type: IOS_GET_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: IOS_GET_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: IOS_GET_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const DeleteMultipleIOSApp = (ids) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_MULTIPLE_IOS_APP_LOADING,
            payload: true
        })

        await ApiPost('application/delete_multiple', { ids })
            .then((res) => {
                dispatch({
                    type: DELETE_MULTIPLE_IOS_APP,
                    payload: res
                })
            })

        dispatch({
            type: DELETE_MULTIPLE_IOS_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: DELETE_MULTIPLE_IOS_APP_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_MULTIPLE_IOS_APP_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}
