import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import LogoutPopup from '../../assets/img/sidebar-logos/Logout.png';
import AuthStorage from '../../helper/AuthStorage';

const LogoutModal = ({ onHide, show }) => {

    const navigate = useNavigate()

    const Logout = () => {
        AuthStorage.deauthenticateUser()
        navigate('/')
    }

    return (
        <Modal
            show={show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='login-popup'
        >
            <Modal.Body>
                <div className='logout-popup-icon'>
                    <img src={LogoutPopup} alt='' width="30px" />
                </div>
                <h4>Logout</h4>
                <h2>Are you sure you want to Logout <br /> you account ?</h2>
                <div className='d-flex justify-content-center mt-3 gap-4'>
                    <Button className='header_cancel_button' onClick={onHide}>Cancel</Button>
                    <Button className='header_button' onClick={Logout}>Log Out</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default LogoutModal