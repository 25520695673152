import moment from 'moment'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getByIdOtherApp } from '../../redux/Action/OtherAppAction'
import { GET_BY_ID_OTHER_APP } from '../../redux/type'
import Loading from '../../layout/Loading'
import Logo from '../../assets/img/CloseLogo.png'

const ViewOthers = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()
    const [isoData, setIsoData] = useState()
    const gateByIdOtherAppData = useSelector(state => state.otherapps.getbyidapp)
    const loading = useSelector(state => state.otherapps.loading)

    useEffect(() => {
        if (id) {
            dispatch(getByIdOtherApp(id))
        }
    }, [id])

    useEffect(() => {
        return () => {
            dispatch({
                type: GET_BY_ID_OTHER_APP,
                payload: ''
            })
        }
    }, [])


    useEffect(() => {
        if (gateByIdOtherAppData) {
            setIsoData(Object.keys(gateByIdOtherAppData?.data).filter(ele => !['_id', '__v', 'isDeleted'].includes(ele)).map(key => {
                if (['createdAt', 'updatedAt'].includes(key)) {
                    return ({ key: key, value: moment(gateByIdOtherAppData?.data[key]).format('DD.MM.YYYY') })
                }
                if (gateByIdOtherAppData?.data[key] && typeof gateByIdOtherAppData?.data[key] === "object") {
                    return Object.keys(gateByIdOtherAppData?.data[key]).filter(ele => !['_id', '__v', 'isDeleted'].includes(ele)).map(key_ele => {
                        return ({ key: `${key}`, value: gateByIdOtherAppData?.data[key][key_ele] })
                    })
                } else {
                    return ({ key: key, value: gateByIdOtherAppData?.data[key] })
                }
            }).flat(1))
        }
    }, [gateByIdOtherAppData])

    return (
        <>
            {loading ? <Loading /> : (
                <div className='android-main'>
                    <div className='android-main-card p-0'>
                        <div className='card__header'>
                            <div style={{ display: 'flex', gap: '20px', alignItems: 'center', flexWrap: 'wrap' }}>
                                <img src={gateByIdOtherAppData?.data?.logo || Logo} alt="" width="30px" />
                                <h1 className='title'>
                                    {gateByIdOtherAppData?.data?.name}
                                </h1>
                            </div>
                            <Button className='header_cancel_button' onClick={() => navigate('/others')}>Back</Button>
                        </div>
                        <table className='view_table'>
                            <tbody style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {
                                    isoData && isoData.filter(ele => ele.key !== 'logo' && ele.key !== 'name').map((item) => {
                                        return (
                                            <tr key={item.key} style={{ width: '50%', display: 'flex', justifyContent: 'space-around' }}>
                                                <td>{item.key}</td>
                                                <td>
                                                    {item.value}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    )
}

export default ViewOthers