import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader } from 'react-bootstrap'
import Profile from '../../assets/img/profile.png'
import { dateFunction } from '../../helper/utils'
import Logo from '../../assets/img/CloseLogo.png'
import moment from 'moment'

const getModifiedData = (data) => {
    return Object.keys(data).filter(ele => !['_id', '__v', 'isDeleted', 'application', 'isFavorite'].includes(ele)).map(key => {
        if (['bloggerAccount'].includes(key)) {
            return ({ key: key, value: data[key].email })
        }
        if (['createdBy', 'updatedBy'].includes(key)) {
            return ({ key: key, value: data[key].name })
        }
        if (key == "extraFields") {
            const temp = Object.keys(data[key]).map((ele) => {
                return `${ele} : ${data[key][ele]} \n`
            }).join("");
            return ({ key: key, value: temp })
        }
        if (['createdAt', 'updatedAt'].includes(key)) {
            return ({ key: key, value: moment(data[key]).local().format('DD-MM-YYYY hh:mm:ss a') })
        }
        return ({ key: key, value: data[key] })
    })
}

const ViewActivity = ({ show, onHide, data }) => {
    const [activityLog, setActivityLog] = useState()
    const [apps, setApps] = useState({})

    useEffect(() => {
        if (data) {
            setActivityLog({
                ...data,
                newData: data.newData ? getModifiedData(data.newData)
                    : [],
                oldData: data.oldData ? getModifiedData(data.oldData)
                    : []
            })
            setApps({ old: data?.oldData?.application, new: data?.newData?.application })
        }
    }, [data])
    useEffect(() => {
    }, [activityLog])

    const isChangeInApps = () => {
        if (apps?.old?.length === apps?.new?.length && apps?.new?.length > 0) {
            const oldAppIds = apps.old.map(app => app._id)
            const changedApps = apps.new.filter(app => !oldAppIds.includes(app._id))
            if (changedApps.length > 0) {
                return true
            }
            return false
        }
        return false
    }

    const getDataToMap = () => {
        if (activityLog?.newData?.length && activityLog?.oldData?.length) {
            if (activityLog.newData.length > activityLog.oldData.length) {
                return activityLog.newData
            } else {
                return activityLog.oldData
            }
        } else if (activityLog?.newData?.length) {
            return activityLog.newData
        } else if (activityLog?.oldData?.length) {
            return activityLog.oldData
        } else {
            return []
        }
    }

    return (
        <Modal
            show={show}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='view_activity_modal'
            onHide={onHide}
        >
            <ModalHeader closeButton>
                <img src={['developer', 'blogger'].includes(activityLog?.type) ? Profile : activityLog?.logo ? activityLog?.logo : Logo} alt="" width="25px" />
                <div className='header_detail'>
                    <h4 className='title'>{activityLog?.title}</h4>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <h4 className='type'>{activityLog?.type}</h4>
                        <h4 className='type'>{activityLog?.createdBy}</h4>
                        <h4 className='type'>{activityLog?.createdAt}</h4>
                    </div>
                </div>
            </ModalHeader>
            <Modal.Body>
                <table className='view_activity_modal_table'>
                    <thead>
                        <tr>
                            <th>Field Name</th>
                            <th>Old Data</th>
                            <th>New Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getDataToMap().map(item => {
                            const { key, value } = item;
                            return (
                                <tr key={key}>
                                    <td className='td_fildName'>{key}</td>
                                    <td className={`${activityLog?.oldData?.find(olddata => olddata.key === key)?.value?.toString() !== activityLog?.newData?.find(newdata => newdata.key === key)?.value?.toString() && 'td_design'}`}>
                                        {key === 'logo' ?
                                            <img src={`${activityLog?.oldData?.find(olddata => olddata.key === key)?.value?.toString()}` || Logo} alt='' width="30px" />
                                            : <span className='td_span' style={{ whiteSpace: 'break-spaces' }} >{activityLog?.oldData?.find(olddata => olddata.key === key)?.value?.toString()}</span>}
                                    </td>
                                    <td className={`${activityLog?.oldData?.find(olddata => olddata.key === key)?.value?.toString() !== activityLog?.newData?.find(newdata => newdata.key === key)?.value?.toString() && 'td_design'}`}>
                                        {key === 'logo' ?
                                            <img src={`${activityLog?.newData?.find(newdata => newdata.key === key)?.value?.toString()}` || Logo} alt='' width="30px" />
                                            : <span className='td_span' style={{ whiteSpace: 'break-spaces' }} >{activityLog?.newData?.find(newdata => newdata.key === key)?.value?.toString()}</span>}
                                    </td>
                                </tr>
                            )
                        }
                        )}
                        {((apps.new && apps.new.length > 0) || (apps.old && apps.old.length > 0)) && (
                            <tr>
                                <td className='td_fildName'>Apps</td>
                                <td className={(apps?.old?.length !== apps?.new?.length || isChangeInApps()) ? 'td_design' : ''}>
                                    {
                                        apps?.old?.length > 0 ? (
                                            apps.old.map(app => (
                                                <a key={app._id} style={{ marginRight: '10px' }} href={app.appLink} target="_blank">
                                                    <img className='app_logo' src={app.logo} alt='logo' />
                                                </a>
                                            ))
                                        ) : '-'
                                    }
                                </td>
                                <td className={(apps?.old?.length !== apps?.new?.length || isChangeInApps()) ? 'td_design' : ''}>
                                    {
                                        apps?.new?.length > 0 ? (
                                            apps.new.map(app => (
                                                <a key={app._id} style={{ marginRight: '10px' }} href={app.appLink} target="_blank">
                                                    <img className='app_logo' src={app.logo} alt='logo' />
                                                </a>
                                            ))
                                        ) : '-'
                                    }
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className='d-flex justify-content-end mt-3 gap-4'>
                    <Button className='header_cancel_button' onClick={onHide}>Cancel</Button>
                </div>
            </Modal.Body>
        </Modal >
    )
}

export default ViewActivity