import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { gateByIdIOSApp } from '../../redux/Action/IOSAction'
import { IOS_GET_BY_ID_APP } from '../../redux/type'
import Loading from '../../layout/Loading'
import Logo from '../../assets/img/CloseLogo.png'

const ViewIOS = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()
    const [isoData, setIsoData] = useState()
    const gateByIdIOSAppData = useSelector(state => state.iosapp.getbyidapp)
    const loading = useSelector(state => state.iosapp.loading)

    useEffect(() => {
        if (id) {
            dispatch(gateByIdIOSApp(id))
        }
    }, [id])

    useEffect(() => {
        if (gateByIdIOSAppData) {
            let data = Object.keys(gateByIdIOSAppData.data).filter(ele => !['_id', '__v', 'isDeleted', 'isFavorite', 'extraFields'].includes(ele)).map(key => {
                if (['createdAt', 'updatedAt'].includes(key)) {
                    return ({ key: key, value: moment(gateByIdIOSAppData.data[key]).format('DD.MM.YYYY') })
                }
                if (gateByIdIOSAppData.data[key] && typeof gateByIdIOSAppData.data[key] === "object") {
                    return Object.keys(gateByIdIOSAppData.data[key]).filter(ele => !['_id', '__v', 'isDeleted'].includes(ele)).map(key_ele => {
                        return ({ key: `${key}`, value: gateByIdIOSAppData.data[key][key_ele] })
                    })
                } else {
                    return ({ key: key, value: gateByIdIOSAppData.data[key] })
                }
            }).flat(1)
            if (gateByIdIOSAppData.data?.extraFields) {
                const extraFields = Object.keys(gateByIdIOSAppData.data.extraFields).map((ele) => {
                    return {
                        key: [ele],
                        value: gateByIdIOSAppData.data.extraFields[ele],
                    }
                })
                data = [...data, ...extraFields]
            }
            setIsoData(data)
        }
    }, [gateByIdIOSAppData])

    useEffect(() => {
        return () => {
            dispatch({
                type: IOS_GET_BY_ID_APP,
                payload: ''
            })
        }
    }, [])

    return (
        <>
            {loading ? <Loading /> : (
                <div className='android-main'>
                    <div className='android-main-card p-0'>
                        <div className='card__header'>
                            <div style={{ display: 'flex', gap: '20px', alignItems: 'center', flexWrap: 'wrap' }}>
                                <img src={gateByIdIOSAppData?.data?.logo || Logo} alt="" width="30px" />
                                <h1 className='title'>
                                    {gateByIdIOSAppData?.data?.name}
                                </h1>
                            </div>
                            <div className='header_buttons'>
                                <Button className='header_cancel_button' onClick={() => navigate(`/edit-ios/${id}`)}>Edit</Button>
                                <Button className='header_cancel_button' onClick={() => navigate('/ios')}>Back</Button>
                            </div>
                        </div>
                        <table className='view_table'>
                            <tbody style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {
                                    isoData && isoData.filter(ele => ele.key !== 'logo' && ele.key !== 'name').map((item) => {
                                        return (
                                            <tr key={item.key} style={{ width: '50%', display: 'flex', justifyContent: 'space-around' }}>
                                                <td>{item.key}</td>
                                                <td>
                                                    {item.value}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    )
}

export default ViewIOS