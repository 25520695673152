import { ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import { IS_LOADING, ACTIVITY_LOG, ACTIVITY_LOG_LOADING, ACTIVITY_LOG_ERROR, NOTIFICATION_ACTIVITY_LOG, NOTIFICATION_ACTIVITY_LOG_ERROR, NOTIFICATION_ACTIVITY_LOG_LOADING, ACTIVITY_UPDATE_LOADING, ACTIVITY_UPDATE, ACTIVITY_UPDATE_ERROR, DELETE_MULTIPLE_ACTIVITIES_LOADING, DELETE_MULTIPLE_ACTIVITIES_ERROR, DELETE_MULTIPLE_ACTIVITIES } from "../type"

export const ActivityLogAction = (search, filterType, pagination) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ACTIVITY_LOG_LOADING,
            payload: true
        })

        await ApiGet(`activity_log?search=${search}&filterType=${filterType}&page=${pagination.page}&limit=${pagination.limit}`)
            .then((res) => {
                dispatch({
                    type: ACTIVITY_LOG,
                    payload: res
                })
            })

        dispatch({
            type: ACTIVITY_LOG_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: ACTIVITY_LOG_ERROR,
            payload: err
        })

        dispatch({
            type: ACTIVITY_LOG_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const UpdateActivityStatus = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ACTIVITY_UPDATE_LOADING,
            payload: true
        })

        await ApiPut(`activity_log?id=${id}`)
            .then((res) => {
                dispatch({
                    type: ACTIVITY_UPDATE,
                    payload: res
                })
            })

        dispatch({
            type: ACTIVITY_UPDATE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: ACTIVITY_UPDATE_ERROR,
            payload: err
        })

        dispatch({
            type: ACTIVITY_UPDATE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const NotificationActivityLogAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: NOTIFICATION_ACTIVITY_LOG_LOADING,
            payload: true
        })

        await ApiGet('activity_log/get_notification_activity')
            .then((res) => {
                dispatch({
                    type: NOTIFICATION_ACTIVITY_LOG,
                    payload: res
                })
            })

        dispatch({
            type: NOTIFICATION_ACTIVITY_LOG_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: NOTIFICATION_ACTIVITY_LOG_ERROR,
            payload: err
        })

        dispatch({
            type: NOTIFICATION_ACTIVITY_LOG_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const DeleteMultipleActivityAction = (ids) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_MULTIPLE_ACTIVITIES_LOADING,
            payload: true
        })


        await ApiPost('activity_log/delete_multiple', { ids })
            .then((res) => {
                dispatch({
                    type: DELETE_MULTIPLE_ACTIVITIES,
                    payload: res
                })
            })

        dispatch({
            type: DELETE_MULTIPLE_ACTIVITIES_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: DELETE_MULTIPLE_ACTIVITIES_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_MULTIPLE_ACTIVITIES_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}
