import { CircularProgress } from "@mui/material"

const Loading = () => {
    return (
        <div style={{ height: '' }} className="loading_main">
            <CircularProgress size={50}  sx={{ top: '50%', color:'#7462ff' }} />
        </div>
    )
}

export default Loading