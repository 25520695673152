import { DELETE_USER, DELETE_USER_ERROR, DELETE_USER_LOADING } from "../type"

const deleteuser = {
    loading: false,
    deleteuser: "",
    err: ""
}

export const DeleteUserReducer = (state = deleteuser, action) => {
    switch (action.type) {
        case DELETE_USER_LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case DELETE_USER:
            return {
                ...state,
                deleteuser: action.payload
            }

        case DELETE_USER_ERROR:
            return {
                ...state,
                err: action.payload
            }

        default: return state
    }

}