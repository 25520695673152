import { style } from '@mui/system'
import React from 'react'
import { Col, Form } from 'react-bootstrap'

const Input = ({ type, placeholder, inputClass, value, onChange, disabled = false, lable, lableClass, name, style, onWheel, formErrors }) => {
    return (
        <Form.Group style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Col lg={3} md={4} sm={12} xs={12}>
                <Form.Label className={lableClass}>{lable}</Form.Label>
            </Col>
            <Col lg={7} md={8} sm={12} xs={12}>
                <Form.Control
                    type={type}
                    placeholder={placeholder}
                    className={inputClass}
                    value={value}
                    name={name}
                    onChange={onChange}
                    disabled={disabled}
                    onWheel={onWheel}
                    style={style}
                />
                {formErrors && name in formErrors && formErrors[name] !== null && formErrors[name] !== '' && <label style={{ color: "red" }}>{formErrors[name]}</label>}
            </Col>
        </Form.Group>
    )
}

export default Input