const STORAGEKEY = {
    token: 'token',
    userData: 'userData',
    userId: 'userId',
    layoutData: 'layoutData',
    email: 'email',
    lang: 'i18nextLng'
};

export default STORAGEKEY
