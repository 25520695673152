import React, { useEffect } from 'react'
import AuthLayout from '../layout/AuthLayout'
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Dashboard from './dashboard/Dashboard'
import Layout from '../layout/Layout'
import Login from './login/Login'
import Android from './android/Android'
import AddAndroid from './android/AddAndroid'
import IOS from './ios/IOS'
import AddIOS from './ios/AddIOS'
import DeveloperAccount from './developer/DeveloperAccount'
import AddDeveloper from './developer/AddDeveloper'
import AddBlogger from './blogger/AddBlogger'
import BloggerAccount from './blogger/BloggerAccount'
import Error from './error/Error'
import { Provider } from 'react-redux'
import store from '../redux/store'
import AuthStorage from '../helper/AuthStorage'
import STORAGEKEY from '../config/APP/app.config'
import Others from './others/Others'
import AddOthers from './others/AddOthers'
import ActivityLog from './activityLog/ActivityLog'
import ViewAndroid from './android/ViewAndroid'
import ViewIOS from './ios/ViewIOS'
import AllApps from './allapps/AllApps'
import ViewDeveloper from './developer/ViewDeveloper'
import ViewBlogger from './blogger/ViewBlogger'
import ViewOthers from './others/ViewOthers'
import ResetPassword from './resetPassword.js/ResetPassword'

const Index = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let { pathname } = { ...location };

    useEffect(() => {
        if (!AuthStorage.getStorageJsonData(STORAGEKEY.userData) || !AuthStorage.getToken()) {
            navigate(pathname)
        }
        if (AuthStorage.getStorageJsonData(STORAGEKEY.userData) && AuthStorage.getToken()) {
            if (pathname && pathname !== "/") {
                navigate(pathname)
            } else {
                navigate('/dashboard')
            }
        }
    }, [])

    if (["/"].includes(pathname)) {
        return (
            <Provider store={store}>
                <Layout>
                    <Routes >
                        <Route element={<PublicRoutes />}>
                            <Route path='/' element={<Login />} />
                        </Route>
                    </Routes>
                </Layout>
            </Provider>

        )
    } else if (["/dashboard", "/android", '/all-apps', '/add-android', '/edit-android/:id', "/view-android/:id", '/ios', '/add-ios', '/view-ios/:id', '/edit-ios/:id', '/developer-account', '/view-developer/:id', '/add-developer', '/blogger-account', '/add-blogger', '/view-blogger/:id', '/others', '/add-other', '/view-other/:id', '/edit-other', '/activity-log', '/reset-password'].includes(pathname) || pathname.includes('edit-') || pathname.includes('view-')) {
        return (
            <Provider store={store}>
                <AuthLayout>
                    <Routes >
                        <Route element={<PrivateRoutes />}>
                            <Route path='/dashboard' element={<Dashboard />} />
                            <Route path='/android' element={<Android />} />
                            <Route path='/add-android' element={<AddAndroid />} />
                            <Route path='/view-android/:id' element={<ViewAndroid />} />
                            <Route path='/edit-android/:id' element={<AddAndroid />} />
                            <Route path='/ios' element={<IOS />} />
                            <Route path='/add-ios' element={<AddIOS />} />
                            <Route path='/view-ios/:id' element={<ViewIOS />} />
                            <Route path='/edit-ios/:id' element={<AddIOS />} />
                            <Route path='/developer-account' element={<DeveloperAccount />} />
                            <Route path='/add-developer' element={<AddDeveloper />} />
                            <Route path='/view-developer/:id' element={<ViewDeveloper />} />
                            <Route path='/edit-developer/:id' element={<AddDeveloper />} />
                            <Route path='/blogger-account' element={<BloggerAccount />} />
                            <Route path='/add-blogger' element={<AddBlogger />} />
                            <Route path='/view-blogger/:id' element={<ViewBlogger />} />
                            <Route path='/edit-blogger/:id' element={<AddBlogger />} />
                            <Route path='/all-apps' element={<AllApps />} />
                            <Route path='/others' element={<Others />} />
                            <Route path='/add-other' element={<AddOthers />} />
                            <Route path='/view-other/:id' element={<ViewOthers />} />
                            <Route path='/edit-other/:id' element={<AddOthers />} />
                            <Route path='/activity-log' element={<ActivityLog />} />
                            <Route path='/reset-password' element={<ResetPassword />} />
                        </Route>
                    </Routes>
                </AuthLayout>
            </Provider>
        )
    } else {
        return (
            <Routes>
                <Route path='*' element={<Error />} />
            </Routes>
        )
    }
}

const PublicRoutes = () => {
    const isAuth = AuthStorage.isUserAuthenticated();
    const navigate = useNavigate()
    return !isAuth ? (
        <Outlet />
    ) : (
        navigate(-1)
    );
};

const PrivateRoutes = () => {
    const navigate = useNavigate()
    const isAuth = AuthStorage.isUserAuthenticated();
    return isAuth ? <Outlet /> : <>{navigate('/', { replace: true })}</>;
};


export default Index