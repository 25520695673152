import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Form, Spinner } from 'react-bootstrap'
import Input from '../../components/reusabledcomponent/Input'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AddBloggerAction, DeleteBloggerAction, EditBloggerAction, getBloggerById } from '../../redux/Action/BloggerAction'
import { ADD_BLOGGER_ACCOUNT, ADD_BLOGGER_ERROR, BLOGGER_GET_BY_ID, DELETE_BLOGGER, EDIT_BLOGGER, EDIT_BLOGGER_ERROR } from '../../redux/type'
import { toast } from 'react-toastify'
import { AllAppsNameIdAction } from '../../redux/Action/AppsAction'
import DeleteModal from '../../components/modals/DeleteModal'
import { AccountStatus } from '../../helper/Constant'
import { SaveButton } from '../../components/reusabledcomponent/Button'

const AddBlogger = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const editData = useSelector((state) => state.bloggeraccount.getbyidblogger)
    const addBloggerAccount = useSelector((state) => state.bloggeraccount.addaccount)
    const addBloggerErr = useSelector((state) => state.bloggeraccount.adderr)
    const updatedData = useSelector((state) => state.bloggeraccount.editblogger)
    const editBloggerErr = useSelector((state) => state.bloggeraccount.editerr)
    const deletedData = useSelector((state) => state.bloggeraccount.deleteblogger)
    // const allApps = useSelector((state) => state.allappsnameid.allappsnameid)
    const [formData, setFormData] = useState({})
    const [formErrors, setFormErrors] = useState({})

    useEffect(() => {
        if (id) {
            dispatch(getBloggerById(id))
        }
    }, [id])

    useEffect(() => {
        if (editData) {
            setFormData(editData)
            dispatch({
                type: BLOGGER_GET_BY_ID,
                payload: ''
            })
        }
    }, [editData])

    useEffect(() => {
        if (addBloggerAccount && addBloggerAccount.status === 200) {
            dispatch({
                type: ADD_BLOGGER_ACCOUNT,
                payload: ''
            })
            setFormData({})
            setLoading(false)
            toast.success(addBloggerAccount.message)
            navigate('/blogger-account')
        }
    }, [addBloggerAccount])

    useEffect(() => {
        if (addBloggerErr) {
            dispatch({
                type: ADD_BLOGGER_ERROR,
                payload: ''
            })
            setLoading(false)
            toast.error(addBloggerErr.response?.data?.message)
        }
    }, [addBloggerErr])

    useEffect(() => {
        if (editBloggerErr) {
            dispatch({
                type: EDIT_BLOGGER_ERROR,
                payload: ''
            })
            setLoading(false)
            toast.error(editBloggerErr.response?.data?.message)
        }
    }, [editBloggerErr])

    useEffect(() => {
        if (updatedData && updatedData.status === 200) {
            dispatch({
                type: EDIT_BLOGGER,
                payload: ""
            })
            dispatch({
                type: BLOGGER_GET_BY_ID,
                payload: ""
            })
            setFormData({})
            setLoading(false)
            toast.success(updatedData.data.message)
            navigate('/blogger-account')
        }
    }, [updatedData])

    useEffect(() => {
        if (deletedData && deletedData.status === 200) {
            dispatch({
                type: DELETE_BLOGGER,
                payload: ''
            })
            toast.success("Account Deleted!")
            navigate('/blogger-account')
        }
    }, [deletedData])

    const validation = () => {
        let flag = false
        const error = {}

        if (!formData.email) {
            error.email = "Please enter email id"
            flag = true
        }
        if (formData.email && !formData.email.includes('@' && ".")) {
            error.email = "Please enter valid email id"
            flag = true
        }
        if (!formData.password) {
            error.password = "Please enter password"
            flag = true
        }
        if (!formData.consoleName) {
            error.consoleName = "Please enter console name"
            flag = true
        }
        // if (!formData.application) {
        //     error.application = "Please select Application"
        //     flag = true
        // }
        if (!formData.status) {
            error.status = "Please select status"
            flag = true
        }
        setFormErrors(error);
        return flag
    }

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
        setFormErrors({ ...formErrors, [event.target.name]: '' })
    }

    const onSave = () => {
        setLoading(true)
        if (validation()) {
            setLoading(false)
            return
        }
        if (id) {
            let body = {
                id: id,
                email: formData.email,
                password: formData.password,
                consoleName: formData.consoleName,
                // application: formData.application,
                status: formData.status,
            }
            dispatch(EditBloggerAction(body))
        }
        else {
            let body = {
                email: formData.email,
                password: formData.password,
                consoleName: formData.consoleName,
                // application: formData.application,
                status: formData.status,
            }
            dispatch(AddBloggerAction(body))
        }
    }

    const onConfirmDelete = () => {
        dispatch(DeleteBloggerAction(id))
        setOpenDeleteModal(false)
    }

    return (
        <>
            <div className='android-main'>
                <div className='android-main-card p-0'>
                    <div className='card__header'>
                        <h1 className='title'>{id ? "Edit" : "Add"} Blogger</h1>
                        <div className='header_buttons'>
                            <SaveButton id={id} loading={loading} onSave={onSave} />
                            {id && <Button disabled={loading} className='header_button' onClick={() => setOpenDeleteModal(true)}>Delete</Button>}
                            <Button className='header_cancel_button' onClick={() => navigate('/blogger-account')}>Cancel</Button>
                        </div>
                    </div>
                    <div className='card__body'>
                        <Row>
                            <Col lg={6}>
                                <Row style={{ rowGap: '8px' }}>
                                    <Col xl={12} lg={6} md={6}>
                                        <Input
                                            lable="Blogger Email"
                                            type="text"
                                            inputClass='form-input'
                                            lableClass="form-label"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            disabled={false}
                                        />
                                        {formErrors?.email && <label style={{ color: "red" }}>{formErrors.email}</label>}
                                    </Col>
                                    <Col xl={12} lg={6} md={6}>
                                        <Input
                                            lable="Password"
                                            type="text"
                                            inputClass='form-input'
                                            lableClass="form-label"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            disabled={false}
                                        />
                                        {formErrors?.password && <label style={{ color: "red" }}>{formErrors.password}</label>}
                                    </Col>
                                    <Col xl={12} lg={6} md={6}>
                                        <Input
                                            lable="Console Name"
                                            type="text"
                                            inputClass='form-input'
                                            lableClass="form-label"
                                            name="consoleName"
                                            value={formData.consoleName}
                                            onChange={handleChange}
                                            disabled={false}
                                        />
                                        {formErrors?.consoleName && <label style={{ color: "red" }}>{formErrors.consoleName}</label>}
                                    </Col>
                                    {/* <Col xl={12} lg={6} md={6}>
                                        <div style={{ display: 'flex' }}>
                                            <Col lg={3}>
                                                <label className='form-label'>App Name</label>
                                            </Col>
                                            <Col lg={7}>
                                                <Form.Select className='form-input' name='application' value={formData.application} onChange={(e) => handleChange(e)} aria-label="Default select example">
                                                    <option >Select Application</option>
                                                    {allApps.data?.map(acc => (
                                                        <option value={acc._id}>{acc.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                        </div>
                                        {formErrors?.application && <label style={{ color: "red" }}>{formErrors.application}</label>}
                                    </Col> */}
                                    <Col xl={12} lg={6} md={6}>
                                        <div style={{ display: 'flex' }}>
                                            <Col lg={3}>
                                                <label className='form-label'>Select Status</label>
                                            </Col>
                                            <Col lg={7}>
                                                <Form.Select className='form-input' name='status' value={formData.status} onChange={handleChange} aria-label="Default select example">
                                                    <option >Select Status</option>
                                                    <option value={AccountStatus.Active}>{AccountStatus.Active}</option>
                                                    <option value={AccountStatus.Suspended}>{AccountStatus.Suspended}</option>
                                                </Form.Select>
                                            </Col>
                                        </div>
                                        {formErrors?.status && <label style={{ color: "red" }}>{formErrors.status}</label>}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    {/* <div className='android-main-card-footer'>
                        <Button className='cancel-btn' onClick={() => navigate('/blogger-account')}>Cancel</Button>
                        <Button className='save-btn' onClick={onSave}>Save</Button>
                    </div> */}
                </div>
            </div>
            {openDeleteModal && <DeleteModal show={openDeleteModal} onHide={() => setOpenDeleteModal(false)} onConfirmDelete={onConfirmDelete} />}
        </>
    )
}

export default AddBlogger