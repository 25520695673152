import { ApiGet, ApiPost } from "../../helper/API/ApiData"
import { IS_LOADING, IS_LOGIN_ERROR, IS_LOGIN_LOADING, LOGIN, GET_USERS, GET_USERS_ERROR, GET_USERS_LOADING, RESET_PASSWORD, RESET_PASSWORD_LOADING, RESET_PASSWORD_ERROR } from "../type"
import { toast } from "react-toastify"

export const LoginAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: IS_LOGIN_LOADING,
            payload: true
        })

        await ApiPost('user/signin', body)
            .then((res) => {
                dispatch({
                    type: LOGIN,
                    payload: res
                })
                dispatch({
                    type: IS_LOGIN_ERROR,
                    payload: []
                })
            }).catch((err) => {
                if (!err.response.data) {
                    toast.error("Something went wrong")
                }
                dispatch({
                    type: LOGIN,
                    payload: []
                })
                dispatch({
                    type: IS_LOGIN_ERROR,
                    payload: err.response.data
                })
            })

        dispatch({
            type: IS_LOGIN_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
    catch (err) {
        dispatch({
            type: IS_LOGIN_ERROR,
            payload: err
        })

        dispatch({
            type: IS_LOGIN_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getUsersAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_USERS_LOADING,
            payload: true
        })

        await ApiGet('user')
            .then((res) => {
                dispatch({
                    type: GET_USERS,
                    payload: res
                })
                dispatch({
                    type: GET_USERS_ERROR,
                    payload: []
                })
            }).catch((err) => {
                dispatch({
                    type: LOGIN,
                    payload: []
                })
                dispatch({
                    type: GET_USERS_ERROR,
                    payload: err.response.data
                })
            })

        dispatch({
            type: GET_USERS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
    catch (err) {
        dispatch({
            type: GET_USERS_ERROR,
            payload: err
        })

        dispatch({
            type: GET_USERS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const ResetPasswordAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: RESET_PASSWORD_LOADING,
            payload: true
        })

        ApiPost('user/reset_password', body)
            .then((res) => {
                dispatch({
                    type: RESET_PASSWORD,
                    payload: res
                })
                dispatch({
                    type: RESET_PASSWORD_ERROR,
                    payload: ''
                })
            }).catch((err) => {
                dispatch({
                    type: RESET_PASSWORD,
                    payload: ''
                })
                dispatch({
                    type: RESET_PASSWORD_ERROR,
                    payload: err.response.data
                })
            })

        dispatch({
            type: RESET_PASSWORD_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
    catch (err) {
        dispatch({
            type: RESET_PASSWORD_ERROR,
            payload: err
        })

        dispatch({
            type: RESET_PASSWORD_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

