import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import DashbordCard from '../../components/DashbordCard'
import { DashboardAction } from '../../redux/Action/Dashboard'
import { dateFunction } from '../../helper/utils'
import { NotificationActivityLogAction, UpdateActivityStatus } from '../../redux/Action/ActivityLogAction'
import { Link, useNavigate } from 'react-router-dom'
import Profile from '../../assets/img/profile.png'
import Logo from '../../assets/img/CloseLogo.png'

const getRoute = (item) => {
  if (item.includes('android')) {
    return 'android'
  }
  if (item.includes('ios')) {
    return 'ios'
  }
  if (item.includes('blogger')) {
    return 'blogger-account'
  }
  if (item.includes('developer')) {
    return 'developer-account'
  }
}

const getDashbordData = (data) => {
  return Object.keys(data.data).filter(ele => ele !== 'logo').map(item => {
    return {
      title: item.toUpperCase().split('APP').join(' APP').split('ACC').join(' ACC'),
      appcount: data.data[item].total,
      sub: Object.keys(data.data[item]).filter(key => key !== 'total').map(ele => {
        return { type: ele, counts: data.data[item][ele] }
      }),
      logo: item.includes('android') ? data.data.logo.android : item.includes('ios') ? data.data.logo.ios : [],
      route: getRoute(item)
    }
  })
}

const Dashboard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [dashboardData, setDashboardData] = useState()
  const allData = useSelector((state) => state.dashboard.data)
  const [activityData, setActivityData] = useState([])
  const notifications = useSelector(state => state.activitylog.notificationactivity)

  useEffect(() => {
    dispatch(DashboardAction())
    dispatch(NotificationActivityLogAction())
  }, [])

  useEffect(() => {
    if (notifications) {
      const temp = notifications?.data?.slice(0, 6)
      setActivityData(temp)
    }
  }, [notifications])

  useEffect(() => {
    if (allData) {
      const data = getDashbordData(allData)
      setDashboardData(data)
    }
  }, [allData])

  let DefaultDashboardData = [
    {
      title: "ANDROID APP",
      route: "android",
      logo: [],
      appcount: 0,
      sub: [
        { type: 'inDevelopment', counts: 0 },
        { type: 'live', counts: 0 },
        { type: 'removed', counts: 0 },
        { type: 'suspended', counts: 0 }
      ]
    },
    {
      title: "IOS APP",
      route: "ios",
      logo: [],
      appcount: 0,
      sub: [
        { type: 'inDevelopment', counts: 0 },
        { type: 'live', counts: 0 },
        { type: 'removed', counts: 0 },
        { type: 'suspended', counts: 0 }
      ]
    },
    {
      title: "DEVELOPER ACC",
      route: "developer-account",
      logo: [],
      appcount: 0,
      sub: [
        { type: 'active', counts: 0 },
        { type: 'suspended', counts: 0 },
      ]
    },
    {
      title: "BLOGGER ACC",
      route: "blogger-account",
      logo: [],
      appcount: 0,
      sub: [
        { type: 'active', counts: 0 },
        { type: 'suspended', counts: 0 },
      ]
    },
  ]

  const gateNtificationLogo = (item) => {
    return ['developer', 'blogger'].includes(item.type) ? Profile : item.logo ? item.logo : Logo
  }

  return (
    <>
      <div className='dashboard-main'>
        <Row className='' style={{ rowGap: '30px' }}>
          {dashboardData ? (
            dashboardData.map((item) => (
              <Col xxl={3} xl={4} lg={6} md={6} sm={6} key={item.title}>
                <DashbordCard data={item} />
              </Col>
            ))
          ) : (
            DefaultDashboardData.map((item) => (
              <Col xxl={3} xl={4} lg={6} md={6} sm={6} key={item.title}>
                <DashbordCard data={item} />
              </Col>
            ))
          )}

          {
            activityData.length > 0 &&
            <Col lg={12}>
              <div className="activity_card__header">
                <h1 className='title'>
                  Recent Activities
                </h1>
              </div>
              {activityData.map((item) => (
                <div key={item._id} className="activity_log_main" style={{ cursor: 'pointer' }} onClick={() => { navigate('/activity-log', { state: item._id }); dispatch(UpdateActivityStatus(item._id)) }}>
                  <div style={{ display: 'flex', gap: '15px', alignItems: 'center', width: '50%' }}>
                    <img src={gateNtificationLogo(item) || Logo} alt="" width="25px" />
                    <h1 className="title">{item.title}</h1>
                  </div>
                  <div style={{ display: 'flex', gap: '15px', alignItems: 'center', justifyContent: 'space-between', width: '50%' }}>
                    <h1 className="title">{item.createdBy?.name}</h1>
                    <h4 className="user">
                      {dateFunction(item.createdAt)}
                    </h4>
                  </div>
                </div>
              ))}
              <div style={{ textAlign: 'end' }}>
                <Link to='/activity-log'>...more</Link>
              </div>
            </Col>
          }
        </Row>
      </div>
    </>
  )
}

export default Dashboard