import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_DEVELOPER_APP, ADD_DEVELOPER_ERROR, ADD_DEVELOPER_LOADING, DEVELOPER_APP, DEVELOPER_ERROR, DEVELOPER_LOADING, IS_LOADING, EDIT_DEVELOPER, EDIT_DEVELOPER_LOADING, EDIT_DEVELOPER_ERROR, DEVELOPER_GET_BY_ID, DEVELOPER_GET_BY_ID_ERROR, DEVELOPER_GET_BY_ID_LOADING, DELETE_DEVELOPER_LOADING, DELETE_DEVELOPER, DELETE_DEVELOPER_ERROR, DELETE_MULTIPLE_DEVELOPER_LOADING, DELETE_MULTIPLE_DEVELOPER_ERROR, DELETE_MULTIPLE_DEVELOPER } from "../type"

export const DeveloperAction = (pagination, search, filter) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DEVELOPER_LOADING,
            payload: true
        })


        await ApiGet(`developer_account?page=${pagination.page}&limit=${pagination.limit}&search=${search}&filter=${filter}`)
            .then((res) => {
                dispatch({
                    type: DEVELOPER_APP,
                    payload: res
                })
            })

        dispatch({
            type: DEVELOPER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: DEVELOPER_ERROR,
            payload: err
        })

        dispatch({
            type: DEVELOPER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const AddDeveloperAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_DEVELOPER_LOADING,
            payload: true
        })


        await ApiPost('developer_account', body)
            .then((res) => {
                dispatch({
                    type: ADD_DEVELOPER_APP,
                    payload: res
                })
            })

        dispatch({
            type: ADD_DEVELOPER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: ADD_DEVELOPER_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_DEVELOPER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const DeleteDeveloperAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_DEVELOPER_LOADING,
            payload: true
        })

        await ApiDelete(`developer_account/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_DEVELOPER,
                    payload: res
                })
            })

        dispatch({
            type: DELETE_DEVELOPER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: DELETE_DEVELOPER_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_DEVELOPER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const EditDeveloperAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_DEVELOPER_LOADING,
            payload: true
        })


        await ApiPut("developer_account", body)
            .then((res) => {
                dispatch({
                    type: EDIT_DEVELOPER,
                    payload: res
                })
            })

        dispatch({
            type: EDIT_DEVELOPER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: EDIT_DEVELOPER_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_DEVELOPER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getDeveloperById = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DEVELOPER_GET_BY_ID_LOADING,
            payload: true
        })


        await ApiGet(`developer_account/${id}`)
            .then((res) => {
                dispatch({
                    type: DEVELOPER_GET_BY_ID,
                    payload: res.data
                })
            })

        dispatch({
            type: DEVELOPER_GET_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: DEVELOPER_GET_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: DEVELOPER_GET_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const DeleteMultipleDeveloperAction = (ids) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_MULTIPLE_DEVELOPER_LOADING,
            payload: true
        })

        await ApiPost('developer_account/delete_multiple', { ids })
            .then((res) => {
                dispatch({
                    type: DELETE_MULTIPLE_DEVELOPER,
                    payload: res
                })
            })

        dispatch({
            type: DELETE_MULTIPLE_DEVELOPER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: DELETE_MULTIPLE_DEVELOPER_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_MULTIPLE_DEVELOPER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}
