import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import Input from '../../components/reusabledcomponent/Input'
import Upload from 'rc-upload'
import { Form } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-toastify";
import { AddIOSApp, DeleteIOSApp, EditIOSApp, gateByIdIOSApp } from '../../redux/Action/IOSAction'
import { IOS_ADD_APP, IOS_ADD_ERROR, IOS_DELETE_APP, IOS_EDIT_APP, IOS_EDIT_ERROR, IOS_GET_BY_ID_APP } from '../../redux/type'
// import { DeveloperAction } from '../../redux/Action/DeveloperAction'
import { BloggerAction } from '../../redux/Action/BloggerAction'
import { AppStatus, AppType } from '../../helper/Constant'
import DeleteModal from '../../components/modals/DeleteModal'
import Check from '../../assets/img/check-mark.png'
import { SaveButton } from '../../components/reusabledcomponent/Button'
import { ApiGet, ApiPost } from '../../helper/API/ApiData'
import { CircularProgress } from '@mui/material'
import Logo from '../../assets/img/CloseLogo.png'

const AddIOS = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [file, setFile] = useState(null)
    const [appLogo, setAppLogo] = useState()
    const [formErrors, setFormErrors] = useState("")
    const [formData, setFormData] = useState({})
    const [loading, setLoading] = useState(false)
    const [uploadStatus, setUploadStatus] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [fieldValue, setFieldValue] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [bloggerAccounts, setBloggerAccounts] = useState([])

    const addIOSData = useSelector(state => state.iosapp.addapp)
    const addIOSErr = useSelector(state => state.iosapp.adderr)
    const editData = useSelector(state => state.iosapp.getbyidapp)
    const editIosErr = useSelector(state => state.iosapp.editerr)
    const updatedData = useSelector(state => state.iosapp.editapp)
    const deletedData = useSelector(state => state.iosapp.deleteapp)

    useEffect(() => {
        if (id) {
            dispatch(gateByIdIOSApp(id))
        }
    }, [id])

    useEffect(() => {
        ApiGet('blogger_account/get_for_dropdown')
            .then((res) => {
                if (res && res.status === 200) {
                    setBloggerAccounts(res.data)
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }, [])

    useEffect(() => {
        if (editData && editData.data) {
            const modifiedData = editData.data && editData.data.extraFields ? Object.keys(editData.data.extraFields).map((ele) => {
                return {
                    fieldValue: editData.data.extraFields[ele],
                    fieldName: ele,
                }
            }) : []
            setFieldValue(modifiedData);

            setFormData({
                ...editData.data,
                bloggerAccount: editData.data.bloggerAccount?._id
            })
            setAppLogo(editData.data.logo)
        }
    }, [editData])

    useEffect(() => {
        return () => {
            dispatch({
                type: IOS_GET_BY_ID_APP,
                payload: ''
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (file) {
            var reader = new FileReader();
            reader.onloadend = function () {
                setAppLogo(reader.result)
                setFormData({ ...formData, logo: reader.result })
                setFormErrors({ ...formErrors, file: '' })
            }
            reader.readAsDataURL(file);
        }
    }, [file])

    const uploadProps = {
        customRequest({
            action,
            data,
            file,
            filename,
            headers,
            onError,
            onProgress,
            onSuccess,
            withCredentials,
        }) {
            if (file && file?.type) {
                setFile(file)
            }
        }
    }

    const validation = () => {
        let flag = false
        const error = {}

        if (!formData.name) {
            error.name = "Please enter app name"
            flag = true
        }
        if (!formData.packageName) {
            error.packageName = "Please enter package name"
            flag = true
        }
        setFormErrors(error);
        return flag
    }

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
        setFormErrors({ ...formErrors, [event.target.name]: '' })
    }

    const onSave = () => {
        let extraFields = {};
        fieldValue.forEach((ele) => {
            extraFields = { ...extraFields, [ele.fieldName]: ele.fieldValue }
        })
        setLoading(true)
        if (validation()) {
            setLoading(false)
            return
        }
        if (id) {
            let body = {
                id: id,
                name: formData.name,
                packageName: formData.packageName.trim(),
                status: formData.status,
                version: formData.version,
                bloggerAccount: formData.bloggerAccount,
                developedBy: formData.developedBy,
                logo: appLogo,
                videoUrl: formData.videoUrl,
                fbBannerId: formData.fbBannerId,
                fbNativeId: formData.fbNativeId,
                fbNativeBannerId: formData.fbNativeBannerId,
                fbInterstialId: formData.fbInterstialId,
                bannerId: formData.bannerId,
                nativeId: formData.nativeId,
                interstialId: formData.interstialId,
                appopenId: formData.appopenId,
                rewardId: formData.rewardId,
                secBannerId: formData.secBannerId,
                secNativeId: formData.secNativeId,
                secInterstialId: formData.secInterstialId,
                secAppopenId: formData.secAppopenId,
                addButtonColor: formData.addButtonColor,
                afterClick: formData.afterClick,
                afterClickNative: formData.afterClickNative,
                customNative: formData.customNative,
                customBanner: formData.customBanner,
                customInterstial: formData.customInterstial,
                customAppOpen: formData.customAppOpen,
                exitNative: formData.exitNative,
                removePakageName: formData.removePakageName,
                codeUrl: formData.codeUrl,
                jksFileUrl: formData.jksFileUrl,
                appType: AppType.Ios,
                extraFields: extraFields
            }
            dispatch(EditIOSApp(body))
        }
        else {
            let body = {
                name: formData.name,
                packageName: formData.packageName.trim(),
                developedBy: formData.developedBy,
                appType: AppType.Ios,
            }
            dispatch(AddIOSApp(body))
        }
    }

    useEffect(() => {
        if (addIOSData && addIOSData.status === 200) {
            dispatch({
                type: IOS_ADD_APP,
                payload: ""
            })
            setLoading(false)
            navigate('/ios')
            toast.success(addIOSData.message)
        }
    }, [addIOSData])

    useEffect(() => {
        if (addIOSErr) {
            dispatch({
                type: IOS_ADD_ERROR,
                payload: ""
            })
            setLoading(false)
            toast.error(addIOSErr.response?.data?.message)
        }
    }, [addIOSErr])

    useEffect(() => {
        if (editIosErr) {
            dispatch({
                type: IOS_EDIT_ERROR,
                payload: ""
            })
            setLoading(false)
            toast.error(editIosErr.response?.data?.message)
        }
    }, [editIosErr])

    useEffect(() => {
        if (updatedData && updatedData.status === 200) {
            dispatch({
                type: IOS_EDIT_APP,
                payload: ""
            })
            setFormData({})
            setLoading(false)
            navigate('/ios')
            toast.success(updatedData.data.message)
        }
    }, [updatedData])

    useEffect(() => {
        if (deletedData && deletedData.status === 200) {
            dispatch({
                type: IOS_DELETE_APP,
                payload: ""
            })
            toast.success(deletedData.data.message)
            navigate('/ios')
        }
    }, [deletedData])

    const onConfirmDelete = () => {
        dispatch(DeleteIOSApp(id))
        setOpenDeleteModal(false)
    }

    const handleUpload = async (e, type) => {
        setIsUploading({ ...isUploading, [type]: true })
        const uploadFormData = new FormData();
        uploadFormData.append("file", e.target.files[0]);
        uploadFormData.append("fileName", e.target.files[0]?.name);
        uploadFormData.append("type", type);

        try {
            await ApiPost('application/upload_file', uploadFormData)
                .then((res) => {
                    setIsUploading(false)
                    if (res.status === 200) {
                        setUploadStatus({ ...uploadStatus, [type]: true })
                        if (type === 'jksFiles') {
                            setFormData({
                                ...formData,
                                jksFileUrl: process.env.REACT_APP_API_ENDPOINT + res?.data,
                            })
                        }
                    }
                })
        } catch (e) {
            setIsUploading(false)
            console.log(e);
        }
    }

    const removeExtraField = (e, i) => {
        const removeItem = [...fieldValue];
        removeItem.splice(i, 1);
        setFieldValue(removeItem);
    }

    return (
        <>
            <div className='android-main'>
                <div className='android-main-card p-0'>
                    <div className='card__header'>
                        <h1 className='title'>{id ? "Edit" : "Add"} IOS app</h1>
                        <div className='header_buttons'>
                            <SaveButton id={id} loading={loading} onSave={onSave} />
                            {id && <Button disabled={loading} className='header_button' onClick={() => setOpenDeleteModal(true)}>Delete</Button>}
                            <Button className='header_cancel_button' onClick={() => navigate('/ios')}>Cancel</Button>
                        </div>
                    </div>
                    <div className='card__body'>
                        <Row style={{ rowGap: '8px' }}>
                            <Col xl={6} lg={12}>
                                <Row style={{ rowGap: '8px' }}>
                                    <Col xl={12} lg={12} md={12}>
                                        <Input
                                            lable="Application Name"
                                            type="text"
                                            inputClass='form-input'
                                            lableClass="form-label"
                                            name="name"
                                            value={formData.name}
                                            onChange={(e) => handleChange(e)}
                                            disabled={false}
                                        />
                                        {formErrors?.name && <label style={{ color: "red" }}>{formErrors.name}</label>}
                                    </Col>
                                    <Col xl={12} lg={12} md={12}>
                                        <Input
                                            lable="Package Name"
                                            type="text"
                                            inputClass='form-input'
                                            lableClass="form-label"
                                            name="packageName"
                                            value={formData.packageName}
                                            onChange={(e) => handleChange(e)}
                                            disabled={id}
                                        />
                                        {formErrors?.packageName && <label style={{ color: "red" }}>{formErrors.packageName}</label>}
                                    </Col>
                                    <Col xl={12} lg={12} md={12}>
                                        <Input
                                            lable="Developed By"
                                            type="text"
                                            inputClass='form-input'
                                            lableClass="form-label"
                                            name="developedBy"
                                            value={formData.developedBy}
                                            onChange={(e) => handleChange(e)}
                                            disabled={false}
                                        />
                                    </Col>

                                    {id && (
                                        <>
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="Version"
                                                    type="text"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="version"
                                                    value={formData.version}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={false}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <Col lg={3} md={4} sm={12} xs={12}>
                                                        <label className='form-label'>Blogger Account</label>
                                                    </Col>
                                                    <Col lg={7} md={8} sm={12} xs={12}>
                                                        <Form.Select className='form-input' name='bloggerAccount' value={formData.bloggerAccount} onChange={(e) => handleChange(e)} aria-label="Default select example">
                                                            <option >Select Blogger</option>
                                                            {bloggerAccounts.map(acc => (
                                                                <option key={acc._id} value={acc._id}>{acc.email}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </Col>
                                                </div>
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <Col lg={3} md={4} sm={12} xs={12}>
                                                        <label className='form-label'>Select Status</label>
                                                    </Col>
                                                    <Col lg={7} md={8} sm={12} xs={12}>
                                                        <Form.Select className='form-input' name='status' value={formData.status} onChange={(e) => handleChange(e)} aria-label="Default select example">
                                                            <option >Select Status</option>
                                                            <option value={AppStatus.InDevelopment}>In Development</option>
                                                            {/* <option value={AppStatus.InReview}>In Review</option> */}
                                                            <option value={AppStatus.Live}>Live</option>
                                                            <option value={AppStatus.Removed}>Removed</option>
                                                            <option value={AppStatus.Suspended}>Suspended</option>
                                                        </Form.Select>
                                                    </Col>
                                                </div>
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="Video Url"
                                                    type="text"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="videoUrl"
                                                    value={formData.videoUrl}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={false}
                                                />
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            </Col>
                            {id && (
                                <>
                                    <Col xl={6} lg={12} md={12}>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            <Col lg={3} md={4} sm={12} xs={12}>
                                                <label className="form-label d-block">Logo</label>
                                            </Col>
                                            <Col lg={8} md={8} sm={12} xs={12}>
                                                <Upload {...uploadProps} id="logo" component="div" style={{ display: 'inline-block', width: '100%' }} >
                                                    <div className="resume-file">
                                                        <img src={formData.logo || Logo} style={{ maxWidth: "100px" }} />
                                                    </div>
                                                </Upload>
                                            </Col>
                                        </div>
                                    </Col>
                                    <div className='detail_title'>Monetize Id</div>
                                    <Col xxl={6} xl={12} lg={12}>
                                        <Row style={{ rowGap: '8px' }}>
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="Fb_banner_id"
                                                    type="text"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="fbBannerId"
                                                    value={formData.fbBannerId}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={false}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="Fb_native_id"
                                                    type="text"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="fbNativeId"
                                                    value={formData.fbNativeId}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={false}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="Fb_native_banner_id"
                                                    type="text"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="fbNativeBannerId"
                                                    value={formData.fbNativeBannerId}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={false}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="Fb_interstial_id"
                                                    type="text"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="fbInterstialId"
                                                    value={formData.fbInterstialId}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={false}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <Col lg={3} md={4} sm={12} xs={12}>
                                                        <label className='form-label'>Fb_Admob_Alter</label>
                                                    </Col>
                                                    <Col lg={7} md={8} sm={12} xs={12}>
                                                        <Form.Select className='form-input' name='fbAdmobAlter' value={formData.fbAdmobAlter} onChange={(e) => handleChange(e)} aria-label="Default select example">
                                                            <option >Select Fb_Admob_Alter</option>
                                                            <option value={0}>0</option>
                                                            <option value={1}>1</option>
                                                        </Form.Select>
                                                    </Col>
                                                </div>
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="Banner_id"
                                                    type="text"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="bannerId"
                                                    value={formData.bannerId}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={false}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="Native_id"
                                                    type="text"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="nativeId"
                                                    value={formData.nativeId}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={false}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="Interstial_id"
                                                    type="text"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="interstialId"
                                                    value={formData.interstialId}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={false}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="Appopen_id"
                                                    type="text"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="appopenId"
                                                    value={formData.appopenId}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={false}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="Reward_id"
                                                    type="text"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="rewardId"
                                                    value={formData.rewardId}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={false}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="Sec_banner_id"
                                                    type="text"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="secBannerId"
                                                    value={formData.secBannerId}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={false}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="Sec_native_id"
                                                    type="text"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="secNativeId"
                                                    value={formData.secNativeId}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={false}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="Sec_interstial_id"
                                                    type="text"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="secInterstialId"
                                                    value={formData.secInterstialId}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={false}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="Sec_appopen_id"
                                                    type="text"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="secAppopenId"
                                                    value={formData.secAppopenId}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={false}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="After Click"
                                                    type="number"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="afterClick"
                                                    value={formData.afterClick}
                                                    onChange={(e) => handleChange(e)}
                                                    onWheel={(e) => e.target.blur()}
                                                    disabled={false}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <Col lg={3} md={4} sm={12} xs={12}>
                                                        <label className='form-label'>After_click_native</label>
                                                    </Col>
                                                    <Col lg={7} md={8} sm={12} xs={12}>
                                                        <Form.Select className='form-input' name='afterClickNative' value={formData.afterClickNative} onChange={(e) => handleChange(e)} aria-label="Default select example">
                                                            <option >Select After_click_native</option>
                                                            <option value={0}>0</option>
                                                            <option value={1}>1</option>
                                                            <option value={2}>2</option>
                                                            <option value={3}>3</option>
                                                            <option value={4}>4</option>
                                                        </Form.Select>
                                                    </Col>
                                                </div>
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="Add_button_color"
                                                    type="color"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="addButtonColor"
                                                    value={formData.addButtonColor}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={false}
                                                    style={{ width: '100%' }}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <Col lg={3} md={4} sm={12} xs={12}>
                                                        <label className='form-label'>Custom_native</label>
                                                    </Col>
                                                    <Col lg={7} md={8} sm={12} xs={12}>
                                                        <Form.Select className='form-input' name='customNative' value={formData.customNative} onChange={(e) => handleChange(e)} aria-label="Default select example">
                                                            <option >Select Custom_native</option>
                                                            <option value={0}>0</option>
                                                            <option value={1}>1</option>
                                                        </Form.Select>
                                                    </Col>
                                                </div>
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <Col lg={3} md={4} sm={12} xs={12}>
                                                        <label className='form-label'>Custom_banner</label>
                                                    </Col>
                                                    <Col lg={7} md={8} sm={12} xs={12}>
                                                        <Form.Select className='form-input' name='customBanner' value={formData.customBanner} onChange={(e) => handleChange(e)} aria-label="Default select example">
                                                            <option >Select Custom_banner</option>
                                                            <option value={0}>0</option>
                                                            <option value={1}>1</option>
                                                        </Form.Select>
                                                    </Col>
                                                </div>
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <Col lg={3} md={4} sm={12} xs={12}>
                                                        <label className='form-label'>Custom_interstial</label>
                                                    </Col>
                                                    <Col lg={7} md={8} sm={12} xs={12}>
                                                        <Form.Select className='form-input' name='customInterstial' value={formData.customInterstial} onChange={(e) => handleChange(e)} aria-label="Default select example">
                                                            <option >Select Custom_interstial</option>
                                                            <option value={0}>0</option>
                                                            <option value={1}>1</option>
                                                        </Form.Select>
                                                    </Col>
                                                </div>
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <Col lg={3} md={4} sm={12} xs={12}>
                                                        <label className='form-label'>Custom_appopen</label>
                                                    </Col>
                                                    <Col lg={7} md={8} sm={12} xs={12}>
                                                        <Form.Select className='form-input' name='customAppOpen' value={formData.customAppOpen} onChange={(e) => handleChange(e)} aria-label="Default select example">
                                                            <option >Select Custom_appopen</option>
                                                            <option value={0}>0</option>
                                                            <option value={1}>1</option>
                                                        </Form.Select>
                                                    </Col>
                                                </div>
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <Col lg={3} md={4} sm={12} xs={12}>
                                                        <label className='form-label'>Exit_Native</label>
                                                    </Col>
                                                    <Col lg={7} md={8} sm={12} xs={12}>
                                                        <Form.Select className='form-input' name='exitNative' value={formData.exitNative} onChange={(e) => handleChange(e)} aria-label="Default select example">
                                                            <option >Select Exit_Native</option>
                                                            <option value={0}>0</option>
                                                            <option value={1}>1</option>
                                                        </Form.Select>
                                                    </Col>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <div className='detail_title'>Others</div>
                                    <Col xxl={6} xl={12} lg={12}>
                                        <Row style={{ rowGap: '8px' }} >
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="Remove_pakage_name"
                                                    type="text"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="removePakageName"
                                                    value={formData.removePakageName}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={false}
                                                    width={'100%'}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <Input
                                                    lable="App Code Link"
                                                    type="text"
                                                    inputClass='form-input'
                                                    lableClass="form-label"
                                                    name="codeUrl"
                                                    value={formData.codeUrl}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={false}
                                                    style={{ width: '100%' }}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <Col lg={3} md={4} sm={12} xs={12}>
                                                        <label className="form-label d-block">Upload .Jks</label>
                                                    </Col>
                                                    <Col lg={7} md={8} sm={12} xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <input type='file' onChange={(e) => handleUpload(e, 'jksFiles')} />
                                                        {isUploading ? <CircularProgress size={20} />
                                                            : (uploadStatus && <img style={{ height: '25px' }} src={Check} alt='' />)}
                                                        {formData?.jksFileUrl && <a style={{ marginLeft: '10px' }} target='_blank' href={formData?.jksFileUrl}>View</a>}
                                                    </Col>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <div className='detail_title'>
                                        <Col xxl={5} xl={10} lg={10}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div >Add extra fields</div>
                                                <Button className='header_button mb-3' onClick={() => {
                                                    setFieldValue([...fieldValue, {}])
                                                }}>Add field</Button>
                                            </div>
                                        </Col>
                                    </div>
                                    <Col xxl={6} xl={12} lg={12}>
                                        {fieldValue.map((ele, i) => (
                                            <Row style={{ rowGap: '8px', marginBottom: '15px' }}>
                                                <Col xl={12} lg={12} md={12}>
                                                    <Input
                                                        lable="Field Name"
                                                        type="text"
                                                        inputClass='form-input'
                                                        lableClass="form-label"
                                                        name="codeUrl"
                                                        disabled={false}
                                                        style={{ width: '100%' }}
                                                        value={fieldValue[i]?.fieldName}
                                                        onChange={(e) => {
                                                            fieldValue[i] = {
                                                                ...fieldValue[i],
                                                                fieldName: e.target.value
                                                            }
                                                            setFieldValue(JSON.parse(JSON.stringify(fieldValue)));
                                                        }}
                                                    />
                                                </Col>
                                                <Col xl={12} lg={12} md={12}>
                                                    <Input
                                                        lable="Field Value"
                                                        type="text"
                                                        inputClass='form-input'
                                                        lableClass="form-label"
                                                        name="codeUrl"
                                                        disabled={false}
                                                        style={{ width: '100%' }}
                                                        value={fieldValue[i].fieldValue}
                                                        onChange={(e) => {
                                                            fieldValue[i] = {
                                                                ...fieldValue[i],
                                                                fieldValue: e.target.value
                                                            }
                                                            setFieldValue(JSON.parse(JSON.stringify(fieldValue)));
                                                        }}
                                                    />
                                                </Col>
                                                <Col xl={10} lg={10} md={10}>
                                                    <div style={{ display: 'flex', justifyContent: 'end', }}>
                                                        <Button className='header_cancel_button' onClick={(e) => removeExtraField(e, i)}>Delete</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>
                                </>
                            )}
                        </Row>
                    </div>
                </div>
            </div>
            {openDeleteModal && <DeleteModal show={openDeleteModal} onHide={() => setOpenDeleteModal(false)} onConfirmDelete={onConfirmDelete} />}
        </>
    )
}

export default AddIOS