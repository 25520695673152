import { ADD_OTHER_APP, ADD_OTHER_APP_ERROR, ADD_OTHER_APP_LOADING, OTHER_APP, OTHER_APP_ERROR, OTHER_APP_LOADING, DELETE_OTHER_APP, DELETE_OTHER_APP_ERROR, DELETE_OTHER_APP_LOADING, EDIT_OTHER_APP, EDIT_OTHER_APP_ERROR, EDIT_OTHER_APP_LOADING, GET_BY_ID_OTHER_APP, GET_BY_ID_OTHER_APP_ERROR, GET_BY_ID_OTHER_APP_LOADING, DELETE_MULTIPLE_OTHER_APPS_ERROR, DELETE_MULTIPLE_OTHER_APPS, DELETE_MULTIPLE_OTHER_APPS_LOADING } from "../type";


const otherapp = {
    loading: "",
    app: "",
    err: "",
    addloading: "",
    addapp: "",
    adderr: "",
    deleteloading: "",
    deleteapp: "",
    deleteerr: "",
    editloading: "",
    editapp: "",
    editerr: "",
    getbyidloading: "",
    getbyidapp: "",
    getbyiderr: "",
    deletemultiloading: "",
    deletemultiapp: "",
    deletemultierr: "",
}

export const OtherAppReducer = (state = otherapp, action) => {
    switch (action.type) {
        case OTHER_APP_LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case OTHER_APP:
            return {
                ...state,
                app: action.payload
            }

        case OTHER_APP_ERROR:
            return {
                ...state,
                err: action.payload
            }
        case ADD_OTHER_APP_LOADING:
            return {
                ...state,
                addloading: action.payload
            }

        case ADD_OTHER_APP:
            return {
                ...state,
                addapp: action.payload
            }

        case ADD_OTHER_APP_ERROR:
            return {
                ...state,
                adderr: action.payload
            }
        // <==================Delete===============>
        case DELETE_OTHER_APP_LOADING:
            return {
                ...state,
                deleteloading: action.payload
            }

        case DELETE_OTHER_APP:
            return {
                ...state,
                deleteapp: action.payload
            }

        case DELETE_OTHER_APP_ERROR:
            return {
                ...state,
                deleteerr: action.payload
            }
        // <==================Edit==================>
        case EDIT_OTHER_APP_LOADING:
            return {
                ...state,
                editloading: action.payload
            }

        case EDIT_OTHER_APP:
            return {
                ...state,
                editapp: action.payload
            }

        case EDIT_OTHER_APP_ERROR:
            return {
                ...state,
                editerr: action.payload
            }

        // <=====================Get by id========================>

        case GET_BY_ID_OTHER_APP_LOADING:
            return {
                ...state,
                getbyidloading: action.payload
            }

        case GET_BY_ID_OTHER_APP:
            return {
                ...state,
                getbyidapp: action.payload
            }

        case GET_BY_ID_OTHER_APP_ERROR:
            return {
                ...state,
                getbyiderr: action.payload
            }

        // <=====================Delete Multiple========================>

        case DELETE_MULTIPLE_OTHER_APPS_LOADING:
            return {
                ...state,
                deletemultiloading: action.payload
            }

        case DELETE_MULTIPLE_OTHER_APPS:
            return {
                ...state,
                deletemultiapp: action.payload
            }

        case DELETE_MULTIPLE_OTHER_APPS_ERROR:
            return {
                ...state,
                deletemultierr: action.payload
            }


        default: return state
    }
}
