import { useEffect, useState, useMemo } from "react"
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from "react-redux"
import { dateFunction } from "../../helper/utils"
import { ActivityLogAction, DeleteMultipleActivityAction } from "../../redux/Action/ActivityLogAction"
import View from '../../assets/img/View.png'
import Profile from '../../assets/img/profile.png'
import { Button } from "@mui/material"
import ViewActivity from "../../components/modals/ViewActivity"
import { useLocation, useNavigate } from "react-router-dom"
import { Form } from "react-bootstrap"
import { ModuleType } from "../../helper/Constant"
import Logo from '../../assets/img/CloseLogo.png'
import Loading from "../../layout/Loading"
import Table from '../../components/table/Table';
import DeleteLogo from './../../assets/img/Delete.png'
import DeleteModal from "../../components/modals/DeleteModal";
import { DELETE_MULTIPLE_ACTIVITIES } from "../../redux/type";
import { toast } from "react-toastify";

const ActivityLog = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [activityData, setActivityData] = useState([])
    const [viewData, setViewData] = useState()
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 50,
    })
    const [totalCount, setTotalCount] = useState(0)
    const [viewActivityLog, setviewActivityLog] = useState(false)
    const [filterType, setFilterType] = useState()
    const [search, setSearch] = useState('')
    const [selectedRows, setSelectedRows] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)

    const allActivities = useSelector(state => state.activitylog.activity)
    const deleteMultiple = useSelector((state) => state.activitylog.deletemultiactivity)
    const loading = useSelector(state => state.activitylog.loading)

    useEffect(() => {
        if (location.state) {
            handleView(location.state)
        }
    }, [location.state, activityData])

    useEffect(() => {
        if (allActivities) {
            const data = allActivities.data.map(d => ({
                ...d,
                oldData: d.oldData ? d.oldData : '',
                newData: d.newData ? d.newData : '',
                createdBy: d.createdBy?.name,
                createdAt: dateFunction(d.createdAt)
            }))
            setActivityData(data)
            setTotalCount(allActivities.count)
        }
    }, [allActivities])

    useEffect(() => {
        dispatch(ActivityLogAction(search, filterType, pagination))
    }, [filterType, search, pagination])

    const handleView = (id) => {
        setViewData(activityData?.find((el) => el._id === id))
        setviewActivityLog(true)
    }

    const handleChange = useMemo(
        () =>
            debounce((e) => setSearch(e.target.value), 1000)
        , [search]);

    useEffect(() => {
        return () => handleChange.cancel();
    }, []);

    useEffect(() => {
        if (deleteMultiple && deleteMultiple.status === 200) {
            dispatch({
                type: DELETE_MULTIPLE_ACTIVITIES,
                payload: ""
            })
            toast.success(deleteMultiple.message)
            setSelectedRows([])
            dispatch(ActivityLogAction(search, filterType, pagination))
        }
    }, [deleteMultiple])

    const columns = [
        {
            id: 'logo',
            numeric: true,
            disablePadding: false,
            label: 'Logo',
            selector: 'logo'
        },
        {
            id: 'title',
            numeric: false,
            disablePadding: true,
            label: 'Title',
            selector: 'title'
        },
        {
            id: 'createdBy',
            numeric: true,
            disablePadding: false,
            label: 'User',
            selector: 'createdBy'
        },
        {
            id: 'type',
            numeric: true,
            disablePadding: false,
            label: 'Type',
            selector: 'type'
        },
        {
            id: 'createdAt',
            numeric: true,
            disablePadding: false,
            label: 'Created At',
            selector: 'createdAt'
        },
        {
            id: 'action',
            numeric: true,
            disablePadding: false,
            label: 'Action',
            selector: 'action',
            action_type: ['VIEW'],
        },
    ];

    const getAction = (obj) => {
        if (obj.type == "VIEW") {
            handleView(obj.id)
        }
    }

    const handleMultipleDelete = () => {
        setDeleteModal(true)
    }

    const onConfirmDelete = () => {
        dispatch(DeleteMultipleActivityAction(selectedRows))
        setDeleteModal(false)
    }

    return (
        <>
            <div className='android-main'>
                <div className='android-main-card p-0 position-relative'>
                    <div className='card__header'>
                        <h1 className='title'>Activity Logs</h1>
                        <div className='card_filter'>
                            {selectedRows.length > 0 && (
                                <div >
                                    <img className='multi_delete_icon' onClick={handleMultipleDelete} src={DeleteLogo} alt='' />
                                </div>
                            )}
                            <div>
                                <Form.Select className='form-input' name='Status' value={filterType} onChange={(e) => setFilterType(e.target.value)} aria-label="Default select example">
                                    <option value=''>All</option>
                                    <option value={ModuleType.Application}>Application</option>
                                    <option value={ModuleType.Blogger}>Blogger Acc</option>
                                    <option value={ModuleType.Developer}>Developer Acc</option>
                                    <option value={ModuleType.Other}>Others</option>
                                </Form.Select>
                            </div>
                            <div className='card_filter_search'>
                                <input type='search' placeholder=' Search...' defaultValue={search} onChange={handleChange}></input>
                            </div>
                        </div>
                    </div>
                    {(loading && !activityData.length) ? <Loading /> : (
                        <Table
                            headCells={columns}
                            rows={activityData}
                            getAction={getAction}
                            pagination={pagination}
                            setPagination={setPagination}
                            count={totalCount}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                        />
                    )}
                </div>
            </div>
            {deleteModal && <DeleteModal show={deleteModal} onHide={() => setDeleteModal(false)} onConfirmDelete={onConfirmDelete} />}
            <ViewActivity data={viewData} show={viewActivityLog} onHide={() => { setviewActivityLog(false); navigate(location.pathname, {}) }} />
        </>
    )
}

export default ActivityLog