import React, { useEffect, useState, useMemo } from 'react'
import debounce from 'lodash.debounce';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/table/Table';
import { AppStatus } from '../../helper/Constant';
import Loading from '../../layout/Loading';
import { AllAppsAction, DeleteMultipleAppsAction } from '../../redux/Action/AppsAction';
import { DELETE_MULTIPLE_APPS } from '../../redux/type';
import { toast } from 'react-toastify';
import DeleteLogo from './../../assets/img/Delete.png'
import DeleteModal from '../../components/modals/DeleteModal';

const AllApps = () => {

    const dispatch = useDispatch()
    const [allAppsData, setAllAppsData] = useState([])
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 50,
    })
    const [totalCount, setTotalCount] = useState(0)
    const [filterStatus, setFilterStatus] = useState()
    const [search, setSearch] = useState()
    const [selectedRows, setSelectedRows] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)

    const allApps = useSelector((state) => state.allapps.allapps)
    const deleteMultiple = useSelector((state) => state.allapps.deletemultiapp)
    const loading = useSelector((state) => state.allapps.loading)

    useEffect(() => {
        dispatch(AllAppsAction(pagination, search, filterStatus))
    }, [search, pagination, filterStatus])

    useEffect(() => {
        if (allApps) {
            setAllAppsData(allApps.data)
            setTotalCount(allApps.count)
        }
    }, [allApps])

    useEffect(() => {
        if (deleteMultiple && deleteMultiple.status === 200) {
            dispatch({
                type: DELETE_MULTIPLE_APPS,
                payload: ""
            })
            toast.success(deleteMultiple.message)
            setSelectedRows([])
            dispatch(AllAppsAction(pagination, search, filterStatus))
        }
    }, [deleteMultiple])

    const columns = [
        {
            id: 'logo',
            numeric: false,
            disablePadding: true,
            label: 'App Logo',
            selector: 'logo'
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'App name',
            selector: 'name'
        },
        {
            id: 'platform',
            numeric: false,
            disablePadding: true,
            label: 'Platform',
            selector: 'appType'
        },
        {
            id: 'packagename',
            numeric: true,
            disablePadding: false,
            label: 'Package name',
            selector: 'packageName'
        },
        {
            id: 'status',
            numeric: true,
            disablePadding: false,
            label: 'Status',
            selector: 'status'
        },
        {
            id: 'version',
            numeric: true,
            disablePadding: false,
            label: 'Version',
            selector: 'version'
        },
        {
            id: 'developerAccount',
            numeric: true,
            disablePadding: false,
            label: 'Developer account',
            selector: 'developerAccount'
        },
    ];

    const handleFilterStatus = (e) => {
        setPagination({ ...pagination, page: 0 })
        setFilterStatus(e.target.value)
    }

    const handleChange = useMemo(
        () =>
            debounce((e) => setSearch(e.target.value), 1000)
        , [search]);

    useEffect(() => {
        return () => handleChange.cancel();
    }, []);

    const handleMultipleDelete = () => {
        setDeleteModal(true)
    }

    const onConfirmDelete = () => {
        dispatch(DeleteMultipleAppsAction(selectedRows))
        setDeleteModal(false)
    }

    return (
        <>
            <div className='android-main'>
                <div className='android-main-card p-0'>
                    <div className='card__header'>
                        <h1 className='title'>Total Apps</h1>
                        <div className='card_filter'>
                            {selectedRows.length > 0 && (
                                <div >
                                    <img className='multi_delete_icon' onClick={handleMultipleDelete} src={DeleteLogo} alt='' />
                                </div>
                            )}
                            <div>
                                <Form.Select className='form-input' name='Status' value={filterStatus} onChange={handleFilterStatus} aria-label="Default select example">
                                    <option value=''>All</option>
                                    <option value={AppStatus.InDevelopment}>{AppStatus.InDevelopment}</option>
                                    {/* <option value={AppStatus.InReview}>{AppStatus.InReview}</option> */}
                                    <option value={AppStatus.Live}>{AppStatus.Live}</option>
                                    <option value={AppStatus.Removed}>{AppStatus.Removed}</option>
                                    <option value={AppStatus.Suspended}>{AppStatus.Suspended}</option>
                                </Form.Select>
                            </div>
                            <div className='card_filter_search'>
                                <input type='search' placeholder=' Search...' defaultValue={search} onChange={handleChange}></input>
                            </div>
                        </div>
                    </div>
                    {(loading && !allAppsData.length) ? <Loading /> : (
                        <Table
                            headCells={columns}
                            rows={allAppsData}
                            pagination={pagination}
                            setPagination={setPagination}
                            count={totalCount}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                        />
                    )}
                </div>
            </div>
            {deleteModal && <DeleteModal show={deleteModal} onHide={() => setDeleteModal(false)} onConfirmDelete={onConfirmDelete} />}
        </>
    )
}

export default AllApps