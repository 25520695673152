import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import DeletePopup from '../../assets/img/delete-popup.png';

const DeleteModal = ({ show, onHide, onConfirmDelete }) => {
    return (
        <Modal
            show={show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='login-popup'
        >
            <Modal.Body>
                <div className='logout-popup-icon'>
                    <img src={DeletePopup} alt='' />
                </div>
                <h4>Delete Data</h4>
                {/* <h2>This will delete your product from catlog are <br /> you sure, you want to delete ?</h2> */}
                <h2>Are you sure, you want to delete ?</h2>
                <div className='d-flex justify-content-center mt-5 gap-4'>
                    <button className='cancel-btn' onClick={onHide}>Cancel</button>
                    <button className='save-btn' onClick={onConfirmDelete}>Delete</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DeleteModal