import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import { ADD_BLOGGER_ACCOUNT, ADD_BLOGGER_ERROR, ADD_BLOGGER_LOADING, BLOGGER_ACCOUNT, BLOGGER_ERROR, BLOGGER_LOADING, IS_LOADING, EDIT_BLOGGER, EDIT_BLOGGER_LOADING, EDIT_BLOGGER_ERROR, BLOGGER_GET_BY_ID, BLOGGER_GET_BY_ID_ERROR, BLOGGER_GET_BY_ID_LOADING, DELETE_BLOGGER_LOADING, DELETE_BLOGGER, DELETE_BLOGGER_ERROR, DELETE_MULTIPLE_BLOGGER_LOADING, DELETE_MULTIPLE_ANDROID_APP_ERROR, DELETE_MULTIPLE_BLOGGER_ERROR, DELETE_MULTIPLE_BLOGGER } from "../type"

export const BloggerAction = (pagination, search, filter) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: BLOGGER_LOADING,
            payload: true
        })

        await ApiGet(`blogger_account?page=${pagination.page}&limit=${pagination.limit}&search=${search}&filter=${filter}`)
            .then((res) => {
                dispatch({
                    type: BLOGGER_ACCOUNT,
                    payload: res
                })
            })

        dispatch({
            type: BLOGGER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: BLOGGER_ERROR,
            payload: err
        })

        dispatch({
            type: BLOGGER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const AddBloggerAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_BLOGGER_LOADING,
            payload: true
        })


        await ApiPost('blogger_account', body)
            .then((res) => {
                dispatch({
                    type: ADD_BLOGGER_ACCOUNT,
                    payload: res
                })
            })

        dispatch({
            type: ADD_BLOGGER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: ADD_BLOGGER_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_BLOGGER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const DeleteBloggerAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_BLOGGER_LOADING,
            payload: true
        })


        await ApiDelete(`blogger_account/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_BLOGGER,
                    payload: res
                })
            })

        dispatch({
            type: DELETE_BLOGGER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: DELETE_BLOGGER_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_BLOGGER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const EditBloggerAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_BLOGGER_LOADING,
            payload: true
        })


        await ApiPut("blogger_account", body)
            .then((res) => {
                dispatch({
                    type: EDIT_BLOGGER,
                    payload: res
                })
            })

        dispatch({
            type: EDIT_BLOGGER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: EDIT_BLOGGER_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_BLOGGER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getBloggerById = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: BLOGGER_GET_BY_ID_LOADING,
            payload: true
        })


        await ApiGet(`blogger_account/${id}`)
            .then((res) => {
                dispatch({
                    type: BLOGGER_GET_BY_ID,
                    payload: res.data
                })
            })

        dispatch({
            type: BLOGGER_GET_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: BLOGGER_GET_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: BLOGGER_GET_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const DeleteMultipleBloggerAction = (ids) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_MULTIPLE_BLOGGER_LOADING,
            payload: true
        })


        await ApiPost('blogger_account/delete_multiple', { ids })
            .then((res) => {
                dispatch({
                    type: DELETE_MULTIPLE_BLOGGER,
                    payload: res
                })
            })

        dispatch({
            type: DELETE_MULTIPLE_BLOGGER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    } catch (err) {
        dispatch({
            type: DELETE_MULTIPLE_BLOGGER_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_MULTIPLE_BLOGGER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}