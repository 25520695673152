import { IS_LOGIN_ERROR, IS_LOGIN_LOADING, LOGIN, GET_USERS_ERROR, GET_USERS, GET_USERS_LOADING, RESET_PASSWORD_LOADING, RESET_PASSWORD, RESET_PASSWORD_ERROR } from "../type"

const login = {
    loading: false,
    login: null,
    err: null,
    userloading: false,
    users: null,
    usererr: null,
    currentuser: null,
    resetpassloading: null,
    resetpass: null,
    resetpasserr: null
}
export const LoginReducer = (state = login, action) => {
    switch (action.type) {
        case IS_LOGIN_LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case LOGIN:
            return {
                ...state,
                login: action.payload
            }

        case IS_LOGIN_ERROR:
            return {
                ...state,
                err: action.payload
            }

        case GET_USERS_LOADING:
            return {
                ...state,
                userloading: action.payload
            }

        case GET_USERS:
            return {
                ...state,
                users: action.payload
            }

        case GET_USERS_ERROR:
            return {
                ...state,
                usererr: action.payload
            }

        case RESET_PASSWORD_LOADING:
            return {
                ...state,
                resetpassloading: action.payload
            }

        case RESET_PASSWORD:
            return {
                ...state,
                resetpass: action.payload
            }

        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                resetpasserr: action.payload
            }

        default: return state
    }
}