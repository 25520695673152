import React, { useEffect, useState, useMemo } from 'react'
import debounce from 'lodash.debounce';
import { Button, Form } from 'react-bootstrap';
import Table from '../../components/table/Table';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteMultipleIOSApp, IOSAction } from '../../redux/Action/IOSAction';
import { DELETE_MULTIPLE_IOS_APP } from '../../redux/type';
import { toast } from 'react-toastify'
import { AppStatus, AppType } from '../../helper/Constant';
import Loading from '../../layout/Loading';
import DeleteModal from '../../components/modals/DeleteModal';
import DeleteLogo from './../../assets/img/Delete.png'
import StarFav from '../../assets/img/star-fav.png'
import Star from '../../assets/img/star.png'
import { Tooltip } from '@mui/material';
import { ApiGet, ApiPut } from '../../helper/API/ApiData';

const IOS = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const urlParamStatus = searchParams.get('status')?.replace(/([A-Z])/g, ' $1').trim().toUpperCase()

    const [allIosData, setAllIosData] = useState([])
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 50,
    })
    const [totalCount, setTotalCount] = useState(0)
    const [filterStatus, setFilterStatus] = useState(urlParamStatus ? urlParamStatus : '')
    const [search, setSearch] = useState()
    const [selectedRows, setSelectedRows] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [isFavorite, setIsFavorite] = useState(false)

    const IosData = useSelector((state) => state.iosapp.app)
    const loading = useSelector((state) => state.iosapp.loading)
    const deleteMultiple = useSelector((state) => state.iosapp.deletemultiapp)

    useEffect(() => {
        dispatch(IOSAction(pagination, search, filterStatus, isFavorite))
        setSelectedRows([])
    }, [search, pagination, filterStatus, urlParamStatus, isFavorite]);

    useEffect(() => {
        if (IosData) {
            setAllIosData(IosData.data)
            setTotalCount(IosData.count)
        }
    }, [IosData])

    useEffect(() => {
        if (deleteMultiple && deleteMultiple.status === 200) {
            dispatch({
                type: DELETE_MULTIPLE_IOS_APP,
                payload: ""
            })
            toast.success(deleteMultiple.message)
            setSelectedRows([])
            dispatch(IOSAction(pagination, search, filterStatus))
        }
    }, [deleteMultiple])

    const columns = [
        {
            id: 'logo',
            numeric: true,
            disablePadding: false,
            label: 'Logo',
            selector: 'logo'
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'App name',
            selector: 'name'
        },
        {
            id: 'packagename',
            numeric: true,
            disablePadding: false,
            label: 'Package name',
            selector: 'packageName'
        },
        {
            id: 'consoleName',
            numeric: true,
            disablePadding: false,
            label: 'Console Name',
            selector: 'consoleName'
        },
        {
            id: 'status',
            numeric: true,
            disablePadding: false,
            label: 'Status',
            selector: 'status'
        },
        {
            id: 'version',
            numeric: true,
            disablePadding: false,
            label: 'Version',
            selector: 'version'
        },
        {
            id: 'developerAccount',
            numeric: true,
            disablePadding: false,
            label: 'Developer account',
            selector: 'developerAccount'
        },
        // {
        //     id: 'bloggerAccountName',
        //     numeric: true,
        //     disablePadding: false,
        //     label: 'Blogger account name',
        //     selector: 'bloggerAccountName'
        // },
        {
            id: 'action',
            numeric: true,
            disablePadding: false,
            label: 'Action',
            selector: 'action',
            action_type: ['VIEW', 'EDIT', 'FAVORITE'],
        },
    ];

    const handleUpdateFavorite = (id) => {
        ApiPut(`application/update_favorite/${id}`)
            .then((res) => {
                if (res && res.status === 200) {
                    toast.success(res.data.message)
                    ApiGet(`application/get_apps_by_status_OR_type?appType=${AppType.Ios}&page=${pagination.page}&limit=${pagination.limit}&search=${search}&filter=${filterStatus}&favorite=${isFavorite}`)
                        .then((res) => {
                            if (res && res.status === 200) {
                                setAllIosData(res.data)
                            }
                        }).catch((err => {
                            toast.error(err?.response?.data?.message)
                        }))
                }
            })
            .catch((err => {
                toast.error(err?.response?.data?.message)
            }))
    }

    const getAction = (obj) => {
        if (obj.type === "VIEW") {
            navigate(`/view-ios/${obj.id}`)
        } else if (obj.type === "EDIT") {
            navigate(`/edit-ios/${obj.id}`)
        } else if (obj.type == "FAVORITE") {
            handleUpdateFavorite(obj.id)
        }
    }

    const handleFilterStatus = (e) => {
        setPagination({ ...pagination, page: 0 })
        setFilterStatus(e.target.value)
    }

    const handleChange = useMemo(
        () =>
            debounce((e) => setSearch(e.target.value), 1000)
        , [search]);

    useEffect(() => {
        return () => handleChange.cancel();
    }, []);

    const handleMultipleDelete = () => {
        setDeleteModal(true)
    }

    const onConfirmDelete = () => {
        dispatch(DeleteMultipleIOSApp(selectedRows))
        setDeleteModal(false)
    }

    return (
        <>
            <div className='android-main'>
                <div className='android-main-card p-0'>
                    <div className='card__header'>
                        <h1 className='title'>IOS Apps</h1>
                        <div className='card_filter'>
                            {selectedRows.length > 0 && (
                                <div >
                                    <img className='multi_delete_icon' onClick={handleMultipleDelete} src={DeleteLogo} alt='' />
                                </div>
                            )}
                            <Tooltip title="Favorites">
                                <div>
                                    <img className='multi_delete_icon' onClick={() => setIsFavorite(!isFavorite)} src={isFavorite ? StarFav : Star} alt='' />
                                </div>
                            </Tooltip>
                            <div>
                                <Form.Select className='form-input' name='Status' value={filterStatus} onChange={handleFilterStatus} aria-label="Default select example">
                                    <option value=''>All</option>
                                    <option value={AppStatus.InDevelopment}>{AppStatus.InDevelopment}</option>
                                    <option value={AppStatus.Live}>{AppStatus.Live}</option>
                                    <option value={AppStatus.Removed}>{AppStatus.Removed}</option>
                                    <option value={AppStatus.Suspended}>{AppStatus.Suspended}</option>
                                </Form.Select>
                            </div>
                            <div className='card_filter_search'>
                                <input type='search' placeholder=' Search...' defaultValue={search} onChange={handleChange}></input>
                            </div>
                            <Button className='header_button' onClick={() => navigate('/add-ios')}>Add App</Button>
                        </div>
                    </div>
                    {(loading && !allIosData.length) ? <Loading /> : (
                        <Table
                            headCells={columns}
                            rows={allIosData}
                            getAction={getAction}
                            pagination={pagination}
                            setPagination={setPagination}
                            count={totalCount}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                        />
                    )}
                </div>
            </div>
            {deleteModal && <DeleteModal show={deleteModal} onHide={() => setDeleteModal(false)} onConfirmDelete={onConfirmDelete} />}
        </>
    )
}

export default IOS