import React, { useState } from 'react'
import Header from './header/Header'
import Sidebar from './sidebar/Sidebar'

const AuthLayout = ({ children }) => {

    const [showSidebar, setShowSidebar] = useState(false)
    return (
        <>
            <div className="children-bg d-flex">
                <Sidebar sendToggle={showSidebar} />
                <div className='authlayout'>
                    <Header getToggle={setShowSidebar} />
                    <div style={{ height: '94vh', overflow: 'hidden' }}>{children}</div>
                </div>
            </div>
        </>
    )
}

export default AuthLayout