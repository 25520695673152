import { DELETE_MULTIPLE_IOS_APP, DELETE_MULTIPLE_IOS_APP_ERROR, DELETE_MULTIPLE_IOS_APP_LOADING, IOS_ADD_APP, IOS_ADD_ERROR, IOS_ADD_LOADING, IOS_APP, IOS_DELETE_APP, IOS_DELETE_ERROR, IOS_DELETE_LOADING, IOS_EDIT_APP, IOS_EDIT_ERROR, IOS_EDIT_LOADING, IOS_ERROR, IOS_GET_BY_ID_APP, IOS_GET_BY_ID_ERROR, IOS_GET_BY_ID_LOADING, IOS_LOADING } from "../type"

const iosapp = {
    loading: "",
    app: "",
    err: "",
    // Add <==========
    addloading: "",
    addapp: "",
    adderr: "",
    // Delete <==========
    deleteloading: "",
    deleteapp: "",
    deleteerr: "",
    // Delete Multiple <==========
    deletemultiloading: "",
    deletemultiapp: "",
    deletemultierr: "",
    // Edit <==========
    editloading: "",
    editapp: "",
    editerr: "",
    // Get By Id <==========
    getbyidloading: "",
    getbyidapp: "",
    getbyiderr: "",
}

export const IOSReducer = (state = iosapp, action) => {
    switch (action.type) {
        case IOS_LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case IOS_APP:
            return {
                ...state,
                app: action.payload
            }

        case IOS_ERROR:
            return {
                ...state,
                err: action.payload
            }

        // ===================> Add IOS <==========================

        case IOS_ADD_LOADING:
            return {
                ...state,
                addloading: action.payload
            }

        case IOS_ADD_APP:
            return {
                ...state,
                addapp: action.payload
            }

        case IOS_ADD_ERROR:
            return {
                ...state,
                adderr: action.payload
            }

        // =============================> Delee IOS <=============================


        case IOS_DELETE_LOADING:
            return {
                ...state,
                deleteloading: action.payload
            }

        case IOS_DELETE_APP:
            return {
                ...state,
                deleteapp: action.payload
            }

        case IOS_DELETE_ERROR:
            return {
                ...state,
                deleteerr: action.payload
            }


        // =============================> Edit IOS <=============================


        case IOS_EDIT_LOADING:
            return {
                ...state,
                editloading: action.payload
            }

        case IOS_EDIT_APP:
            return {
                ...state,
                editapp: action.payload
            }

        case IOS_EDIT_ERROR:
            return {
                ...state,
                editerr: action.payload
            }


        // =============================> Get By Id IOS <=============================


        case IOS_GET_BY_ID_LOADING:
            return {
                ...state,
                getbyidloading: action.payload
            }

        case IOS_GET_BY_ID_APP:
            return {
                ...state,
                getbyidapp: action.payload
            }

        case IOS_GET_BY_ID_ERROR:
            return {
                ...state,
                getbyiderr: action.payload
            }

        // <=====================Delete Multiple========================>

        case DELETE_MULTIPLE_IOS_APP_LOADING:
            return {
                ...state,
                deletemultiloading: action.payload
            }

        case DELETE_MULTIPLE_IOS_APP:
            return {
                ...state,
                deletemultiapp: action.payload
            }

        case DELETE_MULTIPLE_IOS_APP_ERROR:
            return {
                ...state,
                deletemultierr: action.payload
            }

        default: return state
    }
}