import { IS_REGISTER_ERROR, IS_REGISTER_LOADING, REGISTER } from "../type"

const registerData = {
    loading: false,
    register: '',
    err: ''
}

export const RegisterReducer = (state = registerData, action) => {
    switch (action.type) {
        case IS_REGISTER_LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case REGISTER:
            return {
                ...state,
                register:action.payload
            }

        case IS_REGISTER_ERROR:
            return {
                ...state,
                err:action.payload
            }

        default: return state

    }
}