import { DASHBOARD_DATA_LOADING, DASHBOARD_DATA, DASHBOARD_DATA_ERROR } from "../type"

const dashboardData = {
    loading: "",
    data: "",
    err: "",
}

export const DashboardReducer = (state = dashboardData, action) => {
    switch (action.type) {
        case DASHBOARD_DATA_LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case DASHBOARD_DATA:
            return {
                ...state,
                data: action.payload
            }

        case DASHBOARD_DATA_ERROR:
            return {
                ...state,
                err: action.payload
            }

        default: return state
    }
}