import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { LoginReducer } from './Reducer/LoginReducer';
import { RegisterReducer } from './Reducer/RegisterReducer';
import { DeleteUserReducer } from './Reducer/DeleteUserReducer';
import { AddAndroidAppReducer, AndroidAppReducer } from './Reducer/AndroidAppReducer';
import { IOSReducer } from './Reducer/IOSReducer';
import { DeveloperReducer } from './Reducer/DeveloperReducer';
import { BloggerReducer } from './Reducer/BloggerReducer';
import { DashboardReducer } from './Reducer/DashboardReducer';
import { AllAppsReducer } from './Reducer/AllAppsReducer';
import { OtherAppReducer } from './Reducer/OthersReducer';
import { ActivityLogReducer } from './Reducer/ActivityLogReducer';


const middleware = [thunk];

const rootReducer = combineReducers({
  login: LoginReducer,
  register: RegisterReducer,
  deleteuser: DeleteUserReducer,
  androidapp: AndroidAppReducer,
  iosapp: IOSReducer,
  developeraccount: DeveloperReducer,
  bloggeraccount: BloggerReducer,
  dashboard: DashboardReducer,
  allapps: AllAppsReducer,
  otherapps: OtherAppReducer,
  activitylog: ActivityLogReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);


export default store;