import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Input from '../../components/reusabledcomponent/Input'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-toastify";
import { ADD_OTHER_APP, ADD_OTHER_APP_ERROR, DELETE_OTHER_APP, EDIT_OTHER_APP, EDIT_OTHER_APP_ERROR, GET_BY_ID_OTHER_APP } from '../../redux/type'
import { AddOtherApp, DeleteOtherApp, EditOtherApp, getByIdOtherApp } from '../../redux/Action/OtherAppAction'
import DeleteModal from '../../components/modals/DeleteModal'
import Logo from '../../assets/img/CloseLogo.png'
import { SaveButton } from '../../components/reusabledcomponent/Button'

const AddOthers = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [formData, setFormData] = useState({})
    const [formErrors, setFormErrors] = useState()
    const [appLogo, setAppLogo] = useState()
    const [loading, setLoading] = useState(false)

    const editData = useSelector(state => state.otherapps.getbyidapp)
    const addOtherApp = useSelector((state) => state.otherapps.addapp)
    const addOtherAppErr = useSelector((state) => state.otherapps.adderr)
    const updatedData = useSelector(state => state.otherapps.editapp)
    const editOtherAppErr = useSelector(state => state.otherapps.editerr)
    const deletedData = useSelector((state) => state.otherapps.deleteapp)

    useEffect(() => {
        if (id) {
            dispatch(getByIdOtherApp(id))
        }
    }, [id])

    useEffect(() => {
        if (editData) {
            setFormData(
                editData.data
            )
            setAppLogo(editData.data.logo)
            dispatch({
                type: GET_BY_ID_OTHER_APP,
                payload: ''
            })
        }
    }, [editData])

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
        setFormErrors({ ...formErrors, [event.target.name]: '' })
    }

    const onSave = () => {
        setLoading(true)
        if (validation()) {
            setLoading(false)
            return
        }

        if (id) {
            let body = {
                id: id,
                logo: appLogo,
                name: formData.name,
                packageName: formData.packageName,
                notes: formData.notes,
            }
            dispatch(EditOtherApp(body))
        }
        else {
            let body = {
                packageName: formData.packageName,
                notes: formData.notes,
            }
            dispatch(AddOtherApp(body))
        }
    }

    useEffect(() => {
        if (addOtherApp && addOtherApp.status === 200) {
            dispatch({
                type: ADD_OTHER_APP,
                payload: ""
            })
            setFormData({})
            setLoading(false)
            navigate('/others')
            toast.success(addOtherApp.message)
        }
    }, [addOtherApp])

    useEffect(() => {
        if (addOtherAppErr) {
            dispatch({
                type: ADD_OTHER_APP_ERROR,
                payload: ""
            })
            setLoading(false)
            toast.error(addOtherAppErr.response?.data?.message)
        }
    }, [addOtherAppErr])

    useEffect(() => {
        if (editOtherAppErr) {
            dispatch({
                type: EDIT_OTHER_APP_ERROR,
                payload: ""
            })
            setLoading(false)
            toast.error(editOtherAppErr.response?.data?.message)
        }
    }, [editOtherAppErr])

    useEffect(() => {
        if (updatedData && updatedData.status === 200) {
            dispatch({
                type: EDIT_OTHER_APP,
                payload: ""
            })
            dispatch({
                type: GET_BY_ID_OTHER_APP,
                payload: ""
            })
            setFormData({})
            setLoading(false)
            navigate('/others')
            toast.success(updatedData.data.message)
        }
    }, [updatedData])

    useEffect(() => {
        if (deletedData && deletedData.status === 200) {
            dispatch({
                type: DELETE_OTHER_APP,
                payload: ""
            })
            toast.success(deletedData.data.message)
            navigate('/others')
        }
    }, [deletedData])

    const validation = () => {
        let flag = false
        const error = {}

        if (!formData.packageName) {
            error.packageName = "Please enter package name"
            flag = true
        }
        if (!formData.notes) {
            error.notes = "Please enter notes"
            flag = true
        }
        setFormErrors(error);
        return flag
    }

    const onConfirmDelete = () => {
        dispatch(DeleteOtherApp(id))
        setOpenDeleteModal(false)
    }

    return (
        <>
            <div className='android-main'>
                <div className='android-main-card p-0'>
                    <div className='card__header'>
                        <h1 className='title'>{id ? "Edit" : "Add"} Other Apps</h1>
                        <div className='header_buttons'>
                            <SaveButton id={id} loading={loading} onSave={onSave} />
                            {id && <Button disabled={loading} className='header_button' onClick={() => setOpenDeleteModal(true)}>Delete</Button>}
                            <Button className='header_cancel_button' onClick={() => { navigate('/others') }}>Cancel</Button>
                        </div>
                    </div>
                    <div className='card__body'>
                        <Row>
                            <Col lg={6}>
                                <Row style={{ rowGap: '8px' }}>
                                    {id && (
                                        <Col xl={12}>
                                            <Input
                                                lable="App Name"
                                                type="text"
                                                inputClass='form-input'
                                                lableClass="form-label"
                                                name='name'
                                                value={formData.name}
                                                onChange={(e) => handleChange(e)}
                                                disabled={false}
                                            />
                                            {/* {formErrors?.n ame && <label style={{ color: "red" }}>{formErrors.name}</label>} */}
                                        </Col>
                                    )}
                                    <Col xl={12}>
                                        <Input
                                            lable="Package Name"
                                            type="text"
                                            inputClass='form-input'
                                            lableClass="form-label"
                                            name='packageName'
                                            value={formData.packageName}
                                            onChange={(e) => handleChange(e)}
                                            disabled={false}
                                        />
                                        {formErrors?.packageName && <label style={{ color: "red" }}>{formErrors.packageName}</label>}
                                    </Col>
                                    <Col xl={12}>
                                        <Input
                                            lable="Notes"
                                            type="text"
                                            inputClass='form-input'
                                            lableClass="form-label"
                                            name="notes"
                                            value={formData.notes}
                                            onChange={(e) => handleChange(e)}
                                            disabled={false}
                                        />
                                        {formErrors?.notes && <label style={{ color: "red" }}>{formErrors.notes}</label>}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            {openDeleteModal && <DeleteModal show={openDeleteModal} onHide={() => setOpenDeleteModal(false)} onConfirmDelete={onConfirmDelete} />}
        </>
    )
}

export default AddOthers