import { ACTIVITY_LOG, ACTIVITY_LOG_ERROR, ACTIVITY_LOG_LOADING, ACTIVITY_UPDATE, ACTIVITY_UPDATE_ERROR, ACTIVITY_UPDATE_LOADING, DELETE_MULTIPLE_ACTIVITIES, DELETE_MULTIPLE_ACTIVITIES_ERROR, DELETE_MULTIPLE_ACTIVITIES_LOADING, NOTIFICATION_ACTIVITY_LOG, NOTIFICATION_ACTIVITY_LOG_ERROR, NOTIFICATION_ACTIVITY_LOG_LOADING } from "../type"

const bloggeraccount = {
    loading: "",
    activity: "",
    err: "",
    // Notification <==========
    notificationloading: "",
    notificationactivity: "",
    notificationerr: "",
    // Update Activity <==========
    updateloading: "",
    updatedata: "",
    updateerr: "",
    // Delete Multiple <==========
    deletemultiloading: "",
    deletemultiactivity: "",
    deletemultierr: "",
}

export const ActivityLogReducer = (state = bloggeraccount, action) => {
    switch (action.type) {
        case ACTIVITY_LOG_LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case ACTIVITY_LOG:
            return {
                ...state,
                activity: action.payload
            }

        case ACTIVITY_LOG_ERROR:
            return {
                ...state,
                err: action.payload
            }

        case NOTIFICATION_ACTIVITY_LOG_LOADING:
            return {
                ...state,
                notificationloading: action.payload
            }

        case NOTIFICATION_ACTIVITY_LOG:
            return {
                ...state,
                notificationactivity: action.payload
            }

        case NOTIFICATION_ACTIVITY_LOG_ERROR:
            return {
                ...state,
                notificationerr: action.payload
            }
        case ACTIVITY_UPDATE_LOADING:
            return {
                ...state,
                updateloading: action.payload
            }
        case ACTIVITY_UPDATE:
            return {
                ...state,
                updatedata: action.payload
            }
        case ACTIVITY_UPDATE_ERROR:
            return {
                ...state,
                updateerr: action.payload
            }
        case DELETE_MULTIPLE_ACTIVITIES_LOADING:
            return {
                ...state,
                deletemultiloading: action.payload
            }
        case DELETE_MULTIPLE_ACTIVITIES:
            return {
                ...state,
                deletemultiactivity: action.payload
            }
        case DELETE_MULTIPLE_ACTIVITIES_ERROR:
            return {
                ...state,
                deletemultierr: action.payload
            }

        default: return state
    }
}