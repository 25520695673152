import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ShowPassword from '../../assets/img/showpassword.png'
import Hidepassword from '../../assets/img/hidepassword.png'
import STORAGEKEY from '../../config/APP/app.config'
import AuthStorage from '../../helper/AuthStorage'
import { DeleteUserAction } from '../../redux/Action/DeleteUserAction'
import { LoginAction } from '../../redux/Action/LoginAction'
import { RegisterAction } from '../../redux/Action/RegisterAction'
import { LOGIN } from '../../redux/type'
import { Button, Spinner } from 'react-bootstrap'

const Login = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState("password")
    const [formErrors, setFormErrors] = useState()
    const [remember, setRemember] = useState(false)
    const [userData, setUserData] = useState({
        emailId: '',
        password: ''
    })

    const Data = useSelector((state) => state.login.login)
    const loading = useSelector((state) => state.login.loading)
    const Data_ERR = useSelector((state) => state.login.err)

    useEffect(() => {
        if (Data && Data.status === 200) {
            AuthStorage.setStorageData(STORAGEKEY.token, Data.data.token, remember)
            AuthStorage.setStorageData(STORAGEKEY.userData, JSON.stringify(Data.data), remember)
            toast.success(Data.message)
            navigate('/dashboard')
            dispatch({
                type: LOGIN,
                payload: null
            })
        }
    }, [Data])

    useEffect(() => {
        if (Data_ERR) {
            toast.error(Data_ERR.message)
        }
    }, [Data_ERR])


    const hendalChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value })
    }

    const validation = () => {
        let flag = false
        const error = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        if (!userData.emailId) {
            error.email = "Please Enter Email ";
            flag = true
        } else if (!userData.emailId.match(regex)) {
            error.email = "Enter Valid Email";
            flag = true
        }
        if (!userData.password) {
            error.password = "Please Enter Password";
            flag = true
        }
        else if (!userData.password.length > 8) {
            error.password = "Please Password Must be 8 Character"
            flag = true
        }
        setFormErrors(error);
        return flag;
    }

    const login = () => {
        if (validation()) {
            return
        }
        const data = {
            email: userData.emailId,
            password: userData.password
        }
        dispatch(LoginAction(data))
    }

    return (
        <>
            <div className='login-main'>
                <h1 className='login-title'>Log In</h1>
                <form className='login-form'>
                    <label>Email</label>
                    <input placeholder='Enter your Email' value={userData.emailId} name="emailId" onChange={(e) => hendalChange(e)} />
                    {formErrors?.email !== undefined && <label style={{ color: "red" }}>{formErrors.email}</label>}
                    <div className='position-relative'>
                        <label>Password</label>
                        <input placeholder='Enter your Password' value={userData.password} name="password" type={showPassword} onChange={(e) => hendalChange(e)} />
                        <img src={showPassword === "password" ? ShowPassword : Hidepassword} className="show-password" onClick={showPassword === "password" ? () => setShowPassword("text") : () => setShowPassword("password")} />
                        {formErrors?.password !== undefined && <label style={{ color: "red" }}>{formErrors.password}</label>}
                    </div>
                </form>
                <div className='remember-me'>
                    <input id='rememberme' className="form-check-input" type="checkbox" value={remember} onChange={(e) => setRemember(e.target.checked)} />
                    <label htmlFor='rememberme'>Remember me</label>
                </div>
                <button onClick={login} className={loading ? 'disabled' : ''} disabled={loading} >
                    {
                        loading ?
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="false"
                            /> :
                            <span className="">Log In</span>
                    }
                </button>
            </div>
        </>
    )
}

export default Login