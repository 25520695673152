import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { useEffect } from 'react'
import Profile from '../../assets/img/profile.png'
import View from '../../assets/img/View.png'
import Edit from '../../assets/img/Edit.png'
import Star from '../../assets/img/star.png'
import StarFav from '../../assets/img/star-fav.png'
import { AppStatus, ModuleType } from '../../helper/Constant'
import Logo from '../../assets/img/CloseLogo.png'
import { Checkbox, Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, headCells, rowCount, selectedRows, handleSelectAllRows, location } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    <Checkbox
                        color="primary"
                        // indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount === selectedRows.length}
                        onChange={handleSelectAllRows}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                        sx={{
                            display: location == '/all-apps' ? 'none' : ''
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <b>{headCell.label}</b>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function getClassFromRowSelector(selector) {
    if (selector == AppStatus.Live) {
        return "live_status"
    }
    if (selector == AppStatus.InDevelopment) {
        return "development_status"
    }
    if (selector == AppStatus.Removed) {
        return "removed_status"
    }
    if (selector == AppStatus.Suspended) {
        return "suspended_status"
    }
    return ''
}

export default function EnhancedTable({ headCells, rows, getAction, pagination, setPagination, count, selectedRows, setSelectedRows }) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [tableHead, setTableHead] = React.useState([]);
    const [tableRow, setTableRow] = React.useState([]);
    const location = useLocation()

    // const visibleRows = React.useMemo(
    //     () =>
    //         stableSort(rows, getComparator(order, orderBy)).slice(
    //             pagination.page * 50,
    //             pagination.page * 50 + 50,
    //         ),
    //     [order, orderBy, pagination.page],
    // );
    // console.log('visibleRows', visibleRows)


    useEffect(() => {
        if (headCells && headCells.length && rows) {
            const obj = headCells.find((ele) => ele.selector == 'action');
            if (obj?.selector === 'action') {
                rows.forEach((ele) => {
                    if ('action_type' in obj) {
                        ele['action'] = (
                            <>
                                {obj.action_type.includes('VIEW') && (
                                    <img src={View} onClick={() => getAction({ type: 'VIEW', id: ele._id })} alt='' width='15px' style={{ cursor: 'pointer', marginRight: '15px' }} />
                                )}
                                {obj.action_type.includes('EDIT') && (
                                    <img src={Edit} onClick={() => getAction({ type: 'EDIT', id: ele._id })} alt='' width='15px' style={{ cursor: 'pointer', marginRight: '15px' }} />
                                )}
                                {obj.action_type.includes('FAVORITE') && (
                                    <img src={ele?.isFavorite ? StarFav : Star} onClick={() => getAction({ type: 'FAVORITE', id: ele._id })} alt='' width='15px' style={{ cursor: 'pointer' }} />
                                )}
                            </>
                        )
                    }
                })
                setTableRow(rows)
            } else {
                setTableRow(rows)
            }
            setTableHead(headCells)
        }
    }, [headCells, rows])


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPagination({ ...pagination, page: newPage })
    };

    const handleChangeRowsPerPage = (event) => {
        setPagination({ page: 0, limit: parseInt(event.target.value, 10) })
    };

    const handleRowSelection = (e, id) => {
        if (e.target.checked) {
            setSelectedRows([...selectedRows, id])
        } else {
            setSelectedRows(selectedRows.filter(e => e !== id))
        }
    }

    const handleSelectAllRows = (e) => {
        if (e.target.checked) {
            setSelectedRows(tableRow.map(row => row._id))
        } else {
            setSelectedRows([])
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer sx={{ paddingBottom: '52px' }}>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={tableRow.length}
                            headCells={tableHead}
                            selectedRows={selectedRows}
                            handleSelectAllRows={handleSelectAllRows}
                            location={location.pathname}
                        />
                        <TableBody>

                            {
                                stableSort(tableRow, getComparator(order, orderBy))
                                    .map((row, index) => {
                                        const isRowSelected = (id) => selectedRows.indexOf(id) !== -1
                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, row.name)}
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row._id}
                                            ><TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={(e) => handleRowSelection(e, row._id)}
                                                        checked={isRowSelected(row._id)}
                                                        sx={{
                                                            display: location.pathname == '/all-apps' ? 'none' : ''
                                                        }}
                                                    />
                                                </TableCell>
                                                {tableHead.length && [...new Array(tableHead.length)].map((_, i) => {
                                                    if (tableHead[i].selector == 'application') {
                                                        return (
                                                            <TableCell key={row._id} align="left">
                                                                {row.application && row.application.length > 0 ? row.application.map(app => (
                                                                    <a key={app._id} style={{ marginRight: '10px' }} href={app.appLink} target="_blank">
                                                                        <img className='app_logo' src={app.logo} alt='logo' />
                                                                    </a>
                                                                )) : '-'}
                                                            </TableCell>
                                                        )
                                                    }
                                                    if (tableHead[i].selector == 'logo') {
                                                        if ("appLink" in row || ("type" in row && [ModuleType.Application, ModuleType.Other].includes(row.type))) {
                                                            if (row[tableHead[i]?.selector]) {
                                                                return (
                                                                    <TableCell key={row._id} align="left">
                                                                        <a href={row.appLink} target="_blank">
                                                                            <img className='app_logo' src={row[tableHead[i]?.selector]} alt='logo' />
                                                                        </a>
                                                                    </TableCell>
                                                                )
                                                            }
                                                            return (
                                                                <TableCell key={row._id} align="left">
                                                                    <a href={row.appLink} target="_blank">
                                                                        <img className='app_logo' src={Logo} alt='logo' />
                                                                    </a>
                                                                </TableCell>
                                                            )
                                                        }
                                                        return (
                                                            <TableCell key={row._id} align="left">
                                                                <img className='app_logo' src={Profile} alt='logo' />
                                                            </TableCell>
                                                        )
                                                    }
                                                    if (tableHead[i].selector == 'consoleLink' && row[tableHead[i].selector] !== '-') {
                                                        return (
                                                            <TableCell key={row._id + i} align="left">
                                                                <a href={row.consoleLink} target="_blank">
                                                                    <span className={getClassFromRowSelector(row[tableHead[i]?.selector])}>{row[tableHead[i]?.selector]}</span>
                                                                </a>
                                                            </TableCell>
                                                        )
                                                    }
                                                    if (tableHead[i].selector == 'notes') {
                                                        return (
                                                            <TableCell key={row._id + i} align="left">
                                                                <Tooltip componentsProps={{ tooltip: { sx: { backgroundColor: row[tableHead[i]?.selector].length > 10 ? '#7462FF' : 'transparent' } } }} title={row[tableHead[i]?.selector].length > 10 && row[tableHead[i]?.selector]}>
                                                                    <span className={getClassFromRowSelector(row[tableHead[i]?.selector])}>
                                                                        {row[tableHead[i]?.selector].slice(0, 10)}
                                                                        {row[tableHead[i]?.selector].length > 10 && '...'}
                                                                    </span>
                                                                </Tooltip>
                                                            </TableCell>
                                                        )
                                                    }
                                                    return (
                                                        <TableCell key={row._id + i} align="left">
                                                            <span className={getClassFromRowSelector(row[tableHead[i]?.selector])}>
                                                                {row[tableHead[i]?.selector]}
                                                            </span>
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        );
                                    })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        bottom: '0px',
                        backgroundColor: '#ffffff',
                        borderRadius: '0px 0px 10px 10px'
                    }}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={count}
                    rowsPerPage={pagination.limit}
                    page={pagination.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );

}
EnhancedTable.defaultProps = {
    getAction: () => { }
}
