import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getByIdAndroidAppAction } from '../../redux/Action/AndroidAppAction';
import { GET_BY_ID_ANDROID_APP } from '../../redux/type';
import moment from 'moment';
import Loading from '../../layout/Loading'
import Logo from '../../assets/img/CloseLogo.png'

const ViewAndroid = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()
    const [allAndroidData, setAllAndroidData] = useState()
    const getByIdAndroid = useSelector(state => state.androidapp.getbyidapp)
    const loading = useSelector(state => state.androidapp.loading)

    useEffect(() => {
        if (id) {
            dispatch(getByIdAndroidAppAction(id))
        }
    }, [id])

    useEffect(() => {
        if (getByIdAndroid) {
            let data = Object.keys(getByIdAndroid.data).filter(ele => !['_id', '__v', 'isDeleted', 'isFavorite', 'extraFields'].includes(ele)).map(key => {
                if (['createdAt', 'updatedAt'].includes(key)) {
                    return ({ key: key, value: moment(getByIdAndroid.data[key]).format('DD.MM.YYYY') })
                }
                if (getByIdAndroid.data[key] && typeof getByIdAndroid.data[key] === "object") {
                    return Object.keys(getByIdAndroid.data[key]).filter(ele => !['_id', '__v', 'isDeleted'].includes(ele)).map(key_ele => {
                        return ({ key: `${key}`, value: getByIdAndroid.data[key][key_ele] })
                    })
                } else {
                    return ({ key: key, value: getByIdAndroid.data[key] })
                }
            }).flat(1)
            if (getByIdAndroid.data?.extraFields) {
                const extraFields = Object.keys(getByIdAndroid.data.extraFields).map((ele) => {
                    return {
                        key: [ele],
                        value: getByIdAndroid.data.extraFields[ele],
                    }
                })
                data = [...data, ...extraFields]
            }
            setAllAndroidData(data)
        }
    }, [getByIdAndroid])

    useEffect(() => {
        return () => {
            dispatch({
                type: GET_BY_ID_ANDROID_APP,
                payload: ''
            })
        }
    }, [])

    return (
        <>
            {loading ? <Loading /> : (
                <div className='android-main'>
                    <div className='android-main-card p-0'>
                        <div className='card__header'>
                            <div style={{ display: 'flex', gap: '20px', alignItems: 'center', flexWrap: 'wrap' }}>
                                <img src={getByIdAndroid?.data?.logo || Logo} alt="" width="30px" />
                                <h1 className='title'>
                                    {getByIdAndroid?.data?.name}
                                </h1>
                            </div>
                            <div className='header_buttons'>
                                <Button className='header_cancel_button' onClick={() => navigate(`/edit-android/${id}`)}>Edit</Button>
                                <Button className='header_cancel_button' onClick={() => navigate('/android')}>Back</Button>
                            </div>
                        </div>
                        <table className='view_table'>
                            <tbody style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {
                                    allAndroidData && allAndroidData.filter(ele => ele.key !== 'logo' && ele.key !== 'name').map((item) => {
                                        return (
                                            <tr key={item.key} style={{ width: '50%', display: 'flex', justifyContent: 'space-around' }}>
                                                <td>{item.key}</td>
                                                <td>
                                                    {item.value}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    )
}

export default ViewAndroid