import { ALL_APPS, ALL_APPS_ERROR, ALL_APPS_LOADING, ALL_APPS_NAME_ID, ALL_APPS_NAME_ID_ERROR, ALL_APPS_NAME_ID_LOADING, DELETE_MULTIPLE_APPS, DELETE_MULTIPLE_APPS_ERROR, DELETE_MULTIPLE_APPS_LOADING } from "../type"

const dashboardData = {
    loading: "",
    allapps: "",
    allappsnameid: "",
    err: "",
    deletemultiloading: "",
    deletemultiapp: "",
    deletemultierr: "",
}

export const AllAppsReducer = (state = dashboardData, action) => {
    switch (action.type) {
        case ALL_APPS_LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case ALL_APPS:
            return {
                ...state,
                allapps: action.payload
            }

        case ALL_APPS_ERROR:
            return {
                ...state,
                err: action.payload
            }

        case ALL_APPS_NAME_ID_LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case ALL_APPS_NAME_ID:
            return {
                ...state,
                allappsnameid: action.payload
            }

        case ALL_APPS_NAME_ID_ERROR:
            return {
                ...state,
                err: action.payload
            }

        case DELETE_MULTIPLE_APPS_LOADING:
            return {
                ...state,
                deletemultiloading: action.payload
            }

        case DELETE_MULTIPLE_APPS:
            return {
                ...state,
                deletemultiapp: action.payload
            }

        case DELETE_MULTIPLE_APPS_ERROR:
            return {
                ...state,
                deletemultierr: action.payload
            }

        default: return state
    }
}