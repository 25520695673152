import { ADD_BLOGGER_ACCOUNT, ADD_BLOGGER_ERROR, ADD_BLOGGER_LOADING, DELETE_BLOGGER, DELETE_BLOGGER_ERROR, DELETE_BLOGGER_LOADING, BLOGGER_APP, BLOGGER_ERROR, BLOGGER_LOADING, EDIT_BLOGGER_LOADING, EDIT_BLOGGER, EDIT_BLOGGER_ERROR, BLOGGER_GET_BY_ID_LOADING, BLOGGER_GET_BY_ID, BLOGGER_GET_BY_ID_ERROR, BLOGGER_ACCOUNT, DELETE_MULTIPLE_BLOGGER_LOADING, DELETE_MULTIPLE_BLOGGER, DELETE_MULTIPLE_BLOGGER_ERROR } from "../type"

const bloggeraccount = {
    loading: "",
    account: "",
    err: "",
    // add <==========
    addloading: "",
    addaccount: "",
    adderr: "",
    // delete <==========
    deleteloading: "",
    deleteblogger: "",
    deleteerr: "",
    // delete multiple<==========
    deletemultiloading: "",
    deletemultiacc: "",
    deletemultierr: "",
    // edit <==========
    editloading: "",
    editblogger: "",
    editerr: "",
    // get by id <======
    getbyidloading: "",
    getbyidblogger: "",
    getbyiderr: "",
}

export const BloggerReducer = (state = bloggeraccount, action) => {
    switch (action.type) {
        case BLOGGER_LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case BLOGGER_ACCOUNT:
            return {
                ...state,
                account: action.payload
            }

        case BLOGGER_ERROR:
            return {
                ...state,
                err: action.payload
            }

        // =======================>Add Account<=========================== 

        case ADD_BLOGGER_LOADING:
            return {
                ...state,
                addloading: action.payload
            }

        case ADD_BLOGGER_ACCOUNT:
            return {
                ...state,
                addaccount: action.payload
            }

        case ADD_BLOGGER_ERROR:
            return {
                ...state,
                adderr: action.payload
            }

        // =======================> Delete Account <=========================== 

        case DELETE_BLOGGER_LOADING:
            return {
                ...state,
                deleteloading: action.payload
            }

        case DELETE_BLOGGER:
            return {
                ...state,
                deleteblogger: action.payload
            }

        case DELETE_BLOGGER_ERROR:
            return {
                ...state,
                deleteerr: action.payload
            }

        // =======================> Edit Account <=========================== 

        case EDIT_BLOGGER_LOADING:
            return {
                ...state,
                editloading: action.payload
            }

        case EDIT_BLOGGER:
            return {
                ...state,
                editblogger: action.payload
            }

        case EDIT_BLOGGER_ERROR:
            return {
                ...state,
                editerr: action.payload
            }

        // =============================> Get By Id Blogger <=============================


        case BLOGGER_GET_BY_ID_LOADING:
            return {
                ...state,
                getbyidloading: action.payload
            }

        case BLOGGER_GET_BY_ID:
            return {
                ...state,
                getbyidblogger: action.payload
            }

        case BLOGGER_GET_BY_ID_ERROR:
            return {
                ...state,
                getbyiderr: action.payload
            }

        // <=====================Delete Multiple========================>

        case DELETE_MULTIPLE_BLOGGER_LOADING:
            return {
                ...state,
                deletemultiloading: action.payload
            }

        case DELETE_MULTIPLE_BLOGGER:
            return {
                ...state,
                deletemultiacc: action.payload
            }

        case DELETE_MULTIPLE_BLOGGER_ERROR:
            return {
                ...state,
                deletemultierr: action.payload
            }

        default: return state
    }
}