import React, { useEffect, useState } from 'react'
import Togal from '../../assets/img/togal.png'
import Notification from '../../assets/img/Notification.png'
import Massge from '../../assets/img/massge.png'
import Profile from '../../assets/img/profile.png'
import AuthStorage from '../../helper/AuthStorage'
import { useDispatch, useSelector } from 'react-redux'
import { getUsersAction } from '../../redux/Action/LoginAction'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { NotificationActivityLogAction, UpdateActivityStatus } from '../../redux/Action/ActivityLogAction'
import { Box, Divider, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { dateFunction } from '../../helper/utils'
import Logo from '../../assets/img/CloseLogo.png'
import closeNotification from '../../assets/img/closeNotification.png'
import { ACTIVITY_UPDATE } from '../../redux/type'
import Loading from '../Loading'

const Header = ({ getToggle }) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [openSidebar, setOpenSidebar] = useState(true)
  // const [profileManu, setProfileManu] = useState(false)
  const [user, setUser] = useState()
  // const [allUsers, setAllUsers] = useState()
  const [notification, setNotification] = useState()
  // const getUsers = useSelector((state) => state.login.users)

  const notifications = useSelector(state => state.activitylog.notificationactivity)
  const updatedActivity = useSelector(state => state.activitylog.updatedata)
  const loading = useSelector(state => state.activitylog.loading)

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [anchorElProfile, setAnchorElProfile] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleBellIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // const profileManu = Boolean(anchorElProfile);
  // const handleProfileClick = (event) => {
  //   setAnchorElProfile(event.currentTarget)
  // }

  useEffect(() => {
    if (updatedActivity && updatedActivity.status === 200) {

      dispatch({
        type: ACTIVITY_UPDATE,
        payload: "",
      })
      dispatch(NotificationActivityLogAction())

    }
  }, [updatedActivity]);

  useEffect(() => {
    getToggle(openSidebar)
  }, [openSidebar]);

  useEffect(() => {
    dispatch(getUsersAction())
    dispatch(NotificationActivityLogAction())
    const notificationInterval = setInterval(() => {
      dispatch(NotificationActivityLogAction())
    }, 1000 * 60 * 2)

    return () => {
      clearInterval(notificationInterval);
    };
  }, [])

  useEffect(() => {
    if (notifications) {
      setNotification(notifications.data)
    }
  }, [notifications])

  useEffect(() => {
    const userData = AuthStorage.getStorageData('userData')
    if (userData) {
      setUser(JSON.parse(userData))
    }
  }, [AuthStorage.getStorageData('userData')])

  const PaperProps = {
    elevation: 0,
    sx: {
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      mt: 1.5,
      '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  }

  return (
    <div className='header-main'>
      <div className='header-togal'>
        <img src={Togal} alt="" onClick={() => setOpenSidebar(!openSidebar)} className='me-3 cursor-pointer' />
      </div>
      <div >
        <Tooltip title="Notification">
          <IconButton
            onClick={handleBellIconClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <img src={Notification} alt='' className={window.innerWidth >= 991 ? "" : "d-none"} />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={() => setAnchorEl(null)}
          onClick={() => setAnchorEl(null)}
          PaperProps={PaperProps}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >{loading ? <Loading /> : (
          <>
            {notification && notification.length ? (
              notification.map((item) => (
                <div key={item._id}>
                  <MenuItem >
                    <div className='closeNotification' onClick={() => dispatch(UpdateActivityStatus(item._id))}>
                      <img src={closeNotification} alt='' />
                    </div>
                    <div className='notification w-100' onClick={() => { navigate('/activity-log', { state: item._id }); dispatch(UpdateActivityStatus(item._id)) }}>
                      <div className='notification_title'>
                        <img src={['developer', 'blogger'].includes(item.type) ? Profile : item.logo ? item.logo : Logo} alt='' width="20px" />
                        <p>
                          {item.title}
                        </p>
                      </div>
                      <div className='notification_detail'>
                        <h2>
                          {item.createdBy?.name}
                        </h2>
                        <h4>
                          {dateFunction(item.createdAt)}
                        </h4>
                      </div>
                    </div>
                  </MenuItem>
                  <Divider style={{ margin: '0px' }} />
                </div>
              ))
            ) : (
              <div className='m-2'>No Notifications</div>
            )}
          </>
        )}
        </Menu>

        <Tooltip title="User">
          <IconButton
            size="small"
            sx={{ ml: 2 }}
            aria-haspopup="true"
          >
            <Box sx={{
              borderRadius: '50%',
              height: '30px',
              width: '30px',
              background: '#6F73CD',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              {user?.name.split(' ').pop()}
            </Box>
          </IconButton>
        </Tooltip>

        {/* <Tooltip title="User">
          <IconButton
            onClick={handleProfileClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={profileManu ? 'account-menu' : undefined}
            aria-haspopup="true"
          aria-expanded={profileManu ? 'true' : undefined}
          >
            {user?.name.split(' ').pop()}
          </IconButton>
        </Tooltip> */}
        {/* <Menu
          anchorEl={anchorElProfile}
          // id="account-menu"
          open={profileManu}
          onClose={() => setAnchorElProfile(null)}
          onClick={() => setAnchorElProfile(null)}
          PaperProps={PaperProps}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {allUsers &&
            allUsers.map((user) => (
              < MenuItem key={user.id} onClick={() => handleLogout(user.id)}>
                <p>{user.name}</p>
              </MenuItem>
            ))
          }
        </Menu> */}
      </div>
    </div >
  )
}

export default Header