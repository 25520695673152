import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import Input from '../../components/reusabledcomponent/Input'
import Upload from 'rc-upload'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AddDeveloperAction, DeleteDeveloperAction, EditDeveloperAction, getDeveloperById } from '../../redux/Action/DeveloperAction'
import { ADD_DEVELOPER_APP, ADD_DEVELOPER_ERROR, DELETE_DEVELOPER, DEVELOPER_GET_BY_ID, EDIT_DEVELOPER, EDIT_DEVELOPER_ERROR } from '../../redux/type'
import { toast } from 'react-toastify'
import DeleteModal from '../../components/modals/DeleteModal'
import { AccountStatus } from '../../helper/Constant'
import { SaveButton } from '../../components/reusabledcomponent/Button'

const AddDeveloper = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [formData, setFormData] = useState({})
    const [formErrors, setFormErrors] = useState({})
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const addAccount = useSelector(state => state.developeraccount.addapp)
    const addDeveloperErr = useSelector(state => state.developeraccount.adderr)
    const editData = useSelector(state => state.developeraccount.getbyiddeveloper)
    const editDeveloperErr = useSelector(state => state.developeraccount.editerr)
    const updatedData = useSelector(state => state.developeraccount.editdeveloper)
    const deletedData = useSelector((state) => state.developeraccount.deletedeveloper)

    useEffect(() => {
        if (id) {
            dispatch(getDeveloperById(id))
        }
    }, [id])

    useEffect(() => {
        if (editData) {
            setFormData(editData)
        }
    }, [editData])

    useEffect(() => {
        return () => {
            dispatch({
                type: DEVELOPER_GET_BY_ID,
                payload: ''
            })
        }
    }, [])

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
        setFormErrors({ ...formErrors, [event.target.name]: '' })
    }

    const validation = () => {
        let flag = false
        const error = {}

        if (!formData.consoleName) {
            error.consoleName = "Please enter account console name"
            flag = true
        }
        if (!formData.email) {
            error.email = "Please enter email id"
            flag = true
        }
        if (formData.email && !formData.email.includes('@' && ".")) {
            error.email = "Please enter valid email id"
            flag = true
        }
        if (!formData.mobile) {
            error.mobile = "Please enter mobile no"
            flag = true
        }
        if (!formData.device) {
            error.device = "Please enter device name"
            flag = true
        }
        if (!formData.subDevice) {
            error.subDevice = "Please enter sub device"
            flag = true
        }
        if (!formData.status) {
            error.status = "Please select status"
            flag = true
        }
        setFormErrors(error);
        return flag
    }

    const onSave = () => {
        setLoading(true)
        if (validation()) {
            setLoading(false)
            return
        }
        if (id) {
            let body = {
                id: id,
                consoleName: formData.consoleName,
                email: formData.email,
                mobile: formData.mobile,
                device: formData.device,
                subDevice: formData.subDevice,
                status: formData.status,
            }
            dispatch(EditDeveloperAction(body))
        }
        else {
            let body = {
                consoleName: formData.consoleName,
                email: formData.email,
                mobile: formData.mobile,
                device: formData.device,
                subDevice: formData.subDevice,
                status: formData.status,
            }
            dispatch(AddDeveloperAction(body))
        }
    }

    useEffect(() => {
        if (addAccount && addAccount.status === 200) {
            dispatch({
                type: ADD_DEVELOPER_APP,
                payload: ""
            })
            setFormData({})
            setLoading(false)
            toast.success("Account Added!")
            navigate('/developer-account')
        }
    }, [addAccount])

    useEffect(() => {
        if (addDeveloperErr) {
            dispatch({
                type: ADD_DEVELOPER_ERROR,
                payload: ''
            })
            setLoading(false)
            toast.error(addDeveloperErr.response?.data?.message)
        }
    }, [addDeveloperErr])

    useEffect(() => {
        if (editDeveloperErr) {
            dispatch({
                type: EDIT_DEVELOPER_ERROR,
                payload: ''
            })
            setLoading(false)
            toast.error(editDeveloperErr.response?.data?.message)
        }
    }, [editDeveloperErr])

    useEffect(() => {
        if (updatedData && updatedData.status === 200) {
            dispatch({
                type: EDIT_DEVELOPER,
                payload: ""
            })
            setFormData({})
            setLoading(false)
            toast.success(updatedData.data.message)
            navigate('/developer-account')
        }
    }, [updatedData])

    useEffect(() => {
        if (deletedData && deletedData.status === 200) {
            dispatch({
                type: DELETE_DEVELOPER,
                payload: ''
            })
            toast.success("Account Deleted!")
            navigate('/developer-account')
        }
    }, [deletedData])

    const onConfirmDelete = () => {
        dispatch(DeleteDeveloperAction(id))
        setOpenDeleteModal(false)
    }

    return (
        <>
            <div className='android-main'>
                <div className='android-main-card p-0'>
                    <div className='card__header'>
                        <h1 className='title'>{id ? "Edit" : "Add"} Developer</h1>
                        <div className='header_buttons'>
                            <SaveButton id={id} loading={loading} onSave={onSave} />
                            {id && <Button disabled={loading} className='header_button' onClick={() => setOpenDeleteModal(true)}>Delete</Button>}
                            <Button className='header_cancel_button' onClick={() => navigate('/developer-account')}>Cancel</Button>
                        </div>
                    </div>
                    <div className='card__body'>
                        <Row>
                            <Col lg={6}>

                                <Row style={{ rowGap: '8px' }}>
                                    <Col xl={12} lg={6} md={6}>
                                        <Input
                                            lable="Console name"
                                            type="text"
                                            inputClass='form-input'
                                            lableClass="form-label"
                                            name="consoleName"
                                            value={formData.consoleName}
                                            onChange={(e) => handleChange(e)}
                                            disabled={false}
                                        />
                                        {formErrors?.consoleName && <label style={{ color: "red" }}>{formErrors.consoleName}</label>}
                                    </Col>
                                    <Col xl={12} lg={6} md={6}>
                                        <Input
                                            lable="Developer email"
                                            type="text"
                                            inputClass='form-input'
                                            lableClass="form-label"
                                            name="email"
                                            value={formData.email}
                                            onChange={(e) => handleChange(e)}
                                            disabled={false}
                                        />
                                        {formErrors?.email && <label style={{ color: "red" }}>{formErrors.email}</label>}
                                    </Col>
                                    <Col xl={12} lg={6} md={6}>
                                        <Input
                                            lable="Mobile number"
                                            type="number"
                                            inputClass='form-input'
                                            lableClass="form-label"
                                            name="mobile"
                                            value={formData.mobile}
                                            onChange={(e) => handleChange(e)}
                                            disabled={false}
                                        />
                                        {formErrors?.mobile && <label style={{ color: "red" }}>{formErrors.mobile}</label>}
                                    </Col>
                                    <Col xl={12} lg={6} md={6}>
                                        <Input
                                            lable="Device name"
                                            type="text"
                                            inputClass='form-input'
                                            lableClass="form-label"
                                            name="device"
                                            value={formData.device}
                                            onChange={(e) => handleChange(e)}
                                            disabled={false}
                                        />
                                        {formErrors?.device && <label style={{ color: "red" }}>{formErrors.device}</label>}
                                    </Col>
                                    <Col xl={12} lg={6} md={6}>
                                        <Input
                                            lable="Sub Device (Access)"
                                            type="text"
                                            inputClass='form-input'
                                            lableClass="form-label"
                                            name="subDevice"
                                            value={formData.subDevice}
                                            onChange={(e) => handleChange(e)}
                                            disabled={false}
                                        />
                                        {formErrors?.subDevice && <label style={{ color: "red" }}>{formErrors.subDevice}</label>}
                                    </Col>
                                    <Col xl={12} lg={6} md={6}>
                                        <div style={{ display: 'flex' }}>
                                            <Col lg={3}>
                                                <label className='form-label'>Status</label>
                                            </Col>
                                            <Col lg={7}>
                                                <Form.Select className='form-input' name='status' value={formData.status} onChange={(e) => handleChange(e)} aria-label="Default select example">
                                                    <option >Select Status</option>
                                                    <option value={AccountStatus.Active}>{AccountStatus.Active}</option>
                                                    <option value={AccountStatus.Suspended}>{AccountStatus.Suspended}</option>
                                                </Form.Select>
                                            </Col>
                                        </div>
                                        {formErrors?.status && <label style={{ color: "red" }}>{formErrors.status}</label>}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    {/* <div className='android-main-card-footer'>
                        <Button className='cancel-btn' onClick={() => navigate('/developer-account')}>Cancel</Button>
                        <Button className='save-btn' onClick={() => onSave()}>Save</Button>
                    </div> */}
                </div>
            </div>
            {openDeleteModal && <DeleteModal show={openDeleteModal} onHide={() => setOpenDeleteModal(false)} onConfirmDelete={onConfirmDelete} />}
        </>
    )
}

export default AddDeveloper