import React from 'react'
import { useNavigate } from 'react-router-dom'
import AppLogos from '../assets/img/app-logos.png'
import Logo from '../assets/img/CloseLogo.png'
import { defaultLogoUrl } from '../helper/Constant'

const DeshbordCard = ({ data }) => {

    const navigate = useNavigate()

    const handleFilteredNavigate = (type) => {
        navigate(`/${data.route}?status=${type}`)
    }

    return (
        <div className='dashbord-card-main'>
            <div className='header' onClick={() => navigate(`/${data.route}`)}>
                <h1 className='title'>{data.title}</h1>
                <div className='py-4 d-flex justify-content-between align-items-center'>
                    <div style={{ display: 'flex', flexDirection: 'row', }}>
                        {data?.logo?.filter(logo => (logo !== defaultLogoUrl) && (logo !== '')).slice(0, 5).map(item => (
                            <img src={item} alt='' width='40px' style={{ marginRight: '-15px', borderRadius: '10px' }} />
                        ))}
                        {
                            data?.logo?.filter(logo => logo !== defaultLogoUrl).length > 5 && <div className='dashboard_extra_logo_count'> <span>+{data?.logo?.filter(logo => logo !== defaultLogoUrl).length - 5}</span> </div>
                        }
                    </div>
                    <h2 className='mb-0 app-count'>{data.appcount}</h2>
                </div>
            </div>
            <div className='card-footer flex-wrap'>
                {data?.sub?.map((item) => {
                    return <div onClick={() => handleFilteredNavigate(item?.type)} key={item.type} className='d-flex align-items-center mt-2 cursor-pointer'>
                        <h5 className='mb-0'>{item?.type}</h5>
                        <div className={`ms-2 ${item?.type}`}>{item.counts}</div>
                    </div>
                })
                }
            </div>
            <div>
                <span className='color-defind'></span>
            </div>
        </div>
    )
}

export default DeshbordCard