import { ADD_ANDROID_APP, ADD_ANDROID_APP_ERROR, ADD_ANDROID_APP_LOADING, ANDROID_APP, ANDROID_APP_ERROR, ANDROID_APP_LOADING, DELETE_ANDROID_APP, DELETE_ANDROID_APP_ERROR, DELETE_ANDROID_APP_LOADING, DELETE_MULTIPLE_ANDROID_APP, DELETE_MULTIPLE_ANDROID_APP_ERROR, DELETE_MULTIPLE_ANDROID_APP_LOADING, EDIT_ANDROID_APP, EDIT_ANDROID_APP_ERROR, EDIT_ANDROID_APP_LOADING, GET_BY_ID_ANDROID_APP, GET_BY_ID_ANDROID_APP_ERROR, GET_BY_ID_ANDROID_APP_LOADING } from "../type";


const androidapp = {
    loading: "",
    app: "",
    err: "",
    // Add <==========
    addloading: "",
    addapp: "",
    adderr: "",
    // Delete <==========
    deleteloading: "",
    deleteapp: "",
    deleteerr: "",
    // Delete Multiple <==========
    deletemultiloading: "",
    deletemultiapp: "",
    deletemultierr: "",
    // Edit <==========
    editloading: "",
    editapp: "",
    editerr: "",
    // Get By Id <==========
    getbyidloading: "",
    getbyidapp: "",
    getbyiderr: "",
    // Update Favorite <==========
    // editfavloading: "",
    // editfav: "",
    // editfaverr: "",
}



export const AndroidAppReducer = (state = androidapp, action) => {
    switch (action.type) {
        case ANDROID_APP_LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case ANDROID_APP:
            return {
                ...state,
                app: action.payload
            }

        case ANDROID_APP_ERROR:
            return {
                ...state,
                err: action.payload
            }
        case ADD_ANDROID_APP_LOADING:
            return {
                ...state,
                addloading: action.payload
            }

        case ADD_ANDROID_APP:
            return {
                ...state,
                addapp: action.payload
            }

        case ADD_ANDROID_APP_ERROR:
            return {
                ...state,
                adderr: action.payload
            }
        // <==================Delete===============>
        case DELETE_ANDROID_APP_LOADING:
            return {
                ...state,
                deleteloading: action.payload
            }

        case DELETE_ANDROID_APP:
            return {
                ...state,
                deleteapp: action.payload
            }

        case DELETE_ANDROID_APP_ERROR:
            return {
                ...state,
                deleteerr: action.payload
            }
        // <==================Edit==================>
        case EDIT_ANDROID_APP_LOADING:
            return {
                ...state,
                editloading: action.payload
            }

        case EDIT_ANDROID_APP:
            return {
                ...state,
                editapp: action.payload
            }

        case EDIT_ANDROID_APP_ERROR:
            return {
                ...state,
                editerr: action.payload
            }

        // <=====================Get by id========================>

        case GET_BY_ID_ANDROID_APP_LOADING:
            return {
                ...state,
                getbyidloading: action.payload
            }

        case GET_BY_ID_ANDROID_APP:
            return {
                ...state,
                getbyidapp: action.payload
            }

        case GET_BY_ID_ANDROID_APP_ERROR:
            return {
                ...state,
                getbyiderr: action.payload
            }

        // <=====================Delete Multiple========================>

        case DELETE_MULTIPLE_ANDROID_APP_LOADING:
            return {
                ...state,
                deletemultiloading: action.payload
            }

        case DELETE_MULTIPLE_ANDROID_APP:
            return {
                ...state,
                deletemultiapp: action.payload
            }

        case DELETE_MULTIPLE_ANDROID_APP_ERROR:
            return {
                ...state,
                deletemultierr: action.payload
            }

        // <=====================Update Favorite========================>

        // case ANDROID_UPDATE_FAVORITE_LOADING:
        //     return {
        //         ...state,
        //         editfavloading: action.payload
        //     }

        // case ANDROID_UPDATE_FAVORITE:
        //     return {
        //         ...state,
        //         editfav: action.payload
        //     }

        // case ANDROID_UPDATE_FAVORITE_ERROR:
        //     return {
        //         ...state,
        //         editfaverr: action.payload
        //     }

        default: return state
    }
}
