import { ADD_DEVELOPER_APP, ADD_DEVELOPER_ERROR, ADD_DEVELOPER_LOADING, DELETE_DEVELOPER, DELETE_DEVELOPER_ERROR, DELETE_DEVELOPER_LOADING, DEVELOPER_APP, DEVELOPER_ERROR, DEVELOPER_LOADING, EDIT_DEVELOPER_LOADING, EDIT_DEVELOPER, EDIT_DEVELOPER_ERROR, DEVELOPER_GET_BY_ID_LOADING, DEVELOPER_GET_BY_ID, DEVELOPER_GET_BY_ID_ERROR, DELETE_MULTIPLE_DEVELOPER_LOADING, DELETE_MULTIPLE_DEVELOPER, DELETE_MULTIPLE_DEVELOPER_ERROR } from "../type"

const developeraccount = {
    loading: "",
    app: "",
    err: "",
    // add <==========
    addloading: "",
    addapp: "",
    adderr: "",
    // delete <==========
    deleteloading: "",
    deletedeveloper: "",
    deleteerr: "",
    // delete multiple<==========
    deletemultiloading: "",
    deletemultiacc: "",
    deletemultierr: "",
    // edit <==========
    editloading: "",
    editdeveloper: "",
    editerr: "",
    // get by id <======
    getbyidloading: "",
    getbyiddeveloper: "",
    getbyiderr: "",
}

export const DeveloperReducer = (state = developeraccount, action) => {
    switch (action.type) {
        case DEVELOPER_LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case DEVELOPER_APP:
            return {
                ...state,
                app: action.payload
            }

        case DEVELOPER_ERROR:
            return {
                ...state,
                err: action.payload
            }

        // =======================>Add Account<=========================== 

        case ADD_DEVELOPER_LOADING:
            return {
                ...state,
                addloading: action.payload
            }

        case ADD_DEVELOPER_APP:
            return {
                ...state,
                addapp: action.payload
            }

        case ADD_DEVELOPER_ERROR:
            return {
                ...state,
                adderr: action.payload
            }

        // =======================> Delete Account <=========================== 

        case DELETE_DEVELOPER_LOADING:
            return {
                ...state,
                deleteloading: action.payload
            }

        case DELETE_DEVELOPER:
            return {
                ...state,
                deletedeveloper: action.payload
            }

        case DELETE_DEVELOPER_ERROR:
            return {
                ...state,
                deleteerr: action.payload
            }

        // =======================> Edit Account <=========================== 

        case EDIT_DEVELOPER_LOADING:
            return {
                ...state,
                editloading: action.payload
            }

        case EDIT_DEVELOPER:
            return {
                ...state,
                editdeveloper: action.payload
            }

        case EDIT_DEVELOPER_ERROR:
            return {
                ...state,
                editerr: action.payload
            }

        // =============================> Get By Id Developer <=============================


        case DEVELOPER_GET_BY_ID_LOADING:
            return {
                ...state,
                getbyidloading: action.payload
            }

        case DEVELOPER_GET_BY_ID:
            return {
                ...state,
                getbyiddeveloper: action.payload
            }

        case DEVELOPER_GET_BY_ID_ERROR:
            return {
                ...state,
                getbyiderr: action.payload
            }

        // <=====================Delete Multiple========================>

        case DELETE_MULTIPLE_DEVELOPER_LOADING:
            return {
                ...state,
                deletemultiloading: action.payload
            }

        case DELETE_MULTIPLE_DEVELOPER:
            return {
                ...state,
                deletemultiacc: action.payload
            }

        case DELETE_MULTIPLE_DEVELOPER_ERROR:
            return {
                ...state,
                deletemultierr: action.payload
            }

        default: return state
    }
}