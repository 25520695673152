import { useEffect, useState } from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import Input from "../../components/reusabledcomponent/Input"
import { ResetPasswordAction } from "../../redux/Action/LoginAction"
import { RESET_PASSWORD, RESET_PASSWORD_ERROR } from "../../redux/type"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const ResetPassword = () => {
    const [formErrors, setFormErrors] = useState()
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    })

    const resetPassword = useSelector((state) => state.login.resetpass)
    const resetPasswordErr = useSelector((state) => state.login.resetpasserr)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
        setFormErrors({ ...formErrors, [event.target.name]: '' })
    }

    useEffect(() => {
        if (resetPassword && resetPassword.status === 200) {
            setFormData({})
            toast.success(resetPassword.message)
            setLoading(false)
            dispatch({
                type: RESET_PASSWORD,
                payload: ""
            })
            navigate('/dashboard')
        }
    }, [resetPassword])

    useEffect(() => {
        if (resetPasswordErr) {
            setLoading(false)
            toast.error(resetPasswordErr.message)
            dispatch({
                type: RESET_PASSWORD_ERROR,
                payload: ""
            })
        }
    }, [resetPasswordErr])

    const validation = () => {
        let flag = false
        const error = {}

        if (!formData.currentPassword) {
            error.currentPassword = "Please enter current password"
            flag = true
        }
        if (!formData.newPassword) {
            error.newPassword = "Please enter new password"
            flag = true
        }
        if (!formData.confirmPassword) {
            error.confirmPassword = "Please enter confirm password"
            flag = true
        }
        if (formData.newPassword !== formData.confirmPassword) {
            error.confirmPassword = "Password must be same!"
            flag = true
        }
        if (formData.newPassword === formData.currentPassword) {
            error.newPassword = "New password cannot be same as current password!"
            flag = true
        }
        setFormErrors(error);
        return flag
    }

    const onSave = () => {
        setLoading(true)
        if (validation()) {
            setLoading(false)
            return
        }
        let body = {
            currentPassword: formData.currentPassword,
            newPassword: formData.newPassword,
        }
        dispatch(ResetPasswordAction(body))
    }
    let l = true

    return (
        <div className='android-main'>
            <div className='android-main-card p-0 position-relative'>
                <div className="reset_password_main">
                    {/* <Row style={{ rowGap: '8px' }}>
                        <Col xl={6}>
                            <h1 className="reset_password_title">Reset Password</h1>
                        </Col>
                    </Row> */}
                    <div className='card__body'>
                        <Row style={{ rowGap: '8px', display: 'flex', flexDirection: 'column' }}>
                            <Col xl={6}>
                                <Input
                                    lable="Current Password"
                                    type="text"
                                    inputClass='form-input'
                                    lableClass="form-label"
                                    name='currentPassword'
                                    value={formData.currentPassword}
                                    onChange={(e) => handleChange(e)}
                                    disabled={false}
                                    formErrors={formErrors}
                                />
                            </Col>
                            <Col xl={6}>
                                <Input
                                    lable="New Password"
                                    type="text"
                                    inputClass='form-input'
                                    lableClass="form-label"
                                    name='newPassword'
                                    value={formData.newPassword}
                                    onChange={(e) => handleChange(e)}
                                    disabled={false}
                                    formErrors={formErrors}
                                />
                            </Col>
                            <Col xl={6}>
                                <Input
                                    lable="Confirm Password"
                                    type="text"
                                    inputClass='form-input'
                                    lableClass="form-label"
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={(e) => handleChange(e)}
                                    disabled={false}
                                    formErrors={formErrors}
                                />
                            </Col>
                        </Row>
                        <Row style={{ rowGap: '8px' }}>
                            <Col xl={6}>
                                <Row>
                                    <Col lg={3} md={4} sm={12} xs={12}></Col>
                                    <Col lg={7} md={8} sm={12} xs={12}>
                                        <div className="reset_password_button">
                                            <Button className="header_button btn btn-primary" onClick={onSave} disabled={loading} style={{ width: '100%' }}>{
                                                loading ?
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="false"
                                                    /> :
                                                    "Reset Password"
                                            }</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;