import { Button, Spinner } from "react-bootstrap"

export const SaveButton = ({ loading, id, onSave }) => {
    return (
        <Button className='header_button' disabled={loading} onClick={onSave}>
            {
                loading ?
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="false"
                    /> :
                    id ? "Save" : "Add"
            }
        </Button>
    )
}