
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Logo from '../../assets/img/logo.png';
import CloseLogo from '../../assets/img/CloseLogo.png';
import DashboardActive from '../../assets/img/sidebar-logos-2/Dashboardactive.png';
import Dashboard from '../../assets/img/sidebar-logos-2/Dashboard.png';
import AndroidActive from '../../assets/img/sidebar-logos-2/Androidactive.png';
import Android from '../../assets/img/sidebar-logos-2/Android.png';
import IOSActive from '../../assets/img/sidebar-logos-2/IOSactive.png';
import IOS from '../../assets/img/sidebar-logos-2/IOS.png';
import DeveloperActive from '../../assets/img/sidebar-logos-2/Developeractive.png';
import Developer from '../../assets/img/sidebar-logos-2/Developer.png';
import BloggerActive from '../../assets/img/sidebar-logos-2/Bloggeractive.png';
import Blogger from '../../assets/img/sidebar-logos-2/Blogger.png';
import liveapp from '../../assets/img/sidebar-logos-2/liveapp.png';
import liveappActive from '../../assets/img/sidebar-logos/liveappactive.png';
import Otheractive from '../../assets/img/sidebar-logos-2/Otheractive.png';
import Other from '../../assets/img/sidebar-logos-2/Other.png';
import LogoutActive from '../../assets/img/sidebar-logos/Logoutactive.png';
import Logout from '../../assets/img/sidebar-logos/Logout.png';
import SidebarArrowActive from '../../assets/img/sidebar-logos/sidebar-arrow-active.png';
import SidebarArrow from '../../assets/img/sidebar-logos/sidebar-arrow.png';
import LogoutModal from '../../components/modals/LogoutModal';
import { useLocation, useNavigate } from 'react-router-dom'
import Activity_Log_active from '../../assets/img/sidebar-logos-2/Activity_Log_active.png'
import Activity_Log from '../../assets/img/sidebar-logos-2/Activity_Log.png'
import ResetPass from '../../assets/img/sidebar-logos-2/ResetPass.png'
import ResetPass_active from '../../assets/img/sidebar-logos-2/ResetPassColor.png'
import { useEffect } from 'react';


const Sidebar = ({ sendToggle }) => {
  const [selected, setSelected] = useState("Dashboard")
  const [logoutPopup, setLogoutPopup] = useState(false)
  const [index, setIndex] = useState(0)

  const navigate = useNavigate()
  const location = useLocation()

  const manes = [
    {
      item: "Dashboard",
      logo: DashboardActive,
      logo2: Dashboard,
      path: '/dashboard',
      // sub: [
      //   {
      //     subitem: 'Live Apps',
      //     path: '/live-apps'
      //   },
      //   {
      //     subitem: 'Android Apps'
      //   },
      //   {
      //     subitem: 'IOS Apps'
      //   },
      //   {
      //     subitem: 'Developer Account'
      //   },
      //   {
      //     subitem: 'Blogger Account'
      //   },
      // ]
    },
    {
      item: "All Apps",
      logo: liveappActive,
      logo2: liveapp,
      path: '/all-apps',
    },
    {
      item: "Android",
      logo: AndroidActive,
      logo2: Android,
      path: '/android'
    },
    {
      item: "IOS",
      logo: IOSActive,
      logo2: IOS,
      path: '/ios',
    },
    {
      item: "Developer Acc",
      logo: DeveloperActive,
      logo2: Developer,
      path: '/developer-account'
    },
    {
      item: "Blogger Acc",
      logo: BloggerActive,
      logo2: Blogger,
      path: '/blogger-account'
    },
    {
      item: "Others",
      logo: Otheractive,
      logo2: Other,
      path: '/others'
    },
    {
      item: "Activity Log",
      logo: Activity_Log_active,
      logo2: Activity_Log,
      path: '/activity-log'
    },
    {
      item: "Reset Password",
      logo: ResetPass,
      logo2: ResetPass_active,
      path: '/reset-password'
    },
  ]

  useEffect(() => {
    manes.map(item => {
      if (location.pathname.replace('/', '').includes(item.path.replace('/', ''))) {
        setSelected(item.item)
      }
    })
  }, [location])

  return (
    <div className={sendToggle ? window.innerWidth >= 991 ? 'sidebar-open' : "sidebar-open-2" : window.innerWidth >= 991 ? 'sidebar-close' : "sidebar-close-2"}>
      <div className='sidebar-logo'>
        {
          sendToggle ?
            <img src={Logo} alt="" width="60%" />
            :
            <img src={CloseLogo} alt="" width="30px" />
        }
      </div>
      <div className='sidebar-manu'>
        <div className='manu-item'>
          {
            manes.map((item, i) =>
              <div key={i}>
                <Button className={`manu-item-btn ${item.item === selected ? 'active' : ''}`} onClick={() => { selected === item.item ? setSelected('') : setSelected(item.item); setIndex(i); navigate(item.path) }}>
                  <img src={item.item === selected ? item.logo : item.logo2} alt='' width="20px" />
                  {sendToggle && <> {item.item}
                    {/* {item?.sub?.length ? <img src={i === index ? SidebarArrowActive : SidebarArrow} alt='' className={`sidebar-arrow ${selected === item.item && 'sub' in item && 'rotate-item-img'}`} /> : ''} */}
                  </>}
                </Button>

                {/* {sendToggle ?
                  <>
                    {selected === item.item && 'sub' in item && item.sub.map(subItem =>
                      <Button className="manu-item-btn-list" onClick={() => { navigate(subItem.path); setSelected() }}>
                        {subItem.subitem}
                      </Button>
                    )}
                  </>
                  :
                  <>
                    {selected === item.item && 'sub' in item &&
                      <div className='link-modal'>
                        {selected === item.item && 'sub' in item && item.sub.map(subItem =>
                          <Button className="manu-item-btn-list" onClick={() => { navigate(subItem.path); setSelected() }}>
                            {subItem.subitem}
                          </Button>
                        )}
                      </div>
                    }
                  </>
                } */}
              </div>
            )
          }
        </div>
        <Button className='logout-btn' onClick={() => setLogoutPopup(true)}>
          <img src={Logout} alt='' width="20px" />
          {sendToggle &&
            "Logout"
          }
        </Button>
      </div>
      {
        logoutPopup && <LogoutModal show={logoutPopup} onHide={() => setLogoutPopup(false)} />
      }
    </div>
  )
}

export default Sidebar